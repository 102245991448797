import { ServiceUnavailable } from '../../constants/actionTypes';

const initialState = {
  emailphonereset: null,
  emailaddress: '',
  phonenumber: '',
  formattedPhonenumber: '',
  onEmailValid: false,
  onPhoneValid: false,
  signUpCompleted: false,
  moveSignUpContinue: false,
  consentbox: '',
  RetrieveCustomerConstentTriggered: false,
  spid: '',
  notifyFios: false,
  notifyFiosTv: false,
  notifyfiveG: false,
};

const notifyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ServiceUnavailable.RESET_EMAIL_PHONE:
      return { ...state, emailphonereset: null };
    case ServiceUnavailable.EMAIL:
      return { ...state, emailaddress: action.value };
    case ServiceUnavailable.PHONE:
      return { ...state, phonenumber: action.value };
    case ServiceUnavailable.FORMATTED_PHONE:
      return { ...state, formattedPhonenumber: action.value };
    case ServiceUnavailable.EMAIL_VALID_FLAG:
      return { ...state, onEmailValid: action.value };
    case ServiceUnavailable.PHONE_VALID_FLAG:
      return { ...state, onPhoneValid: action.value };
    case ServiceUnavailable.SIGN_UP_COMPLETED:
      return { ...state, signUpCompleted: action.value };
    case ServiceUnavailable.MOVE_SIGNUP_CONTINUE:
      return { ...state, moveSignUpContinue: action.value };
    case ServiceUnavailable.RETRIEVE_CUSTOMER_CONSENT:
      return { ...state, consentbox: action.value };
    case ServiceUnavailable.RETRIEVE_CUSTOMER_CONSENT_TRIGGERED:
      return { ...state, RetrieveCustomerConstentTriggered: action.value };
    case ServiceUnavailable.SMSCONSENT_SPID:
      return { ...state, spid: action.value };
    case ServiceUnavailable.NOTIFY_FIOS:
      return { ...state, notifyFios: action.value };
    case ServiceUnavailable.NOTIFY_FIOSTV:
      return { ...state, notifyFiosTv: action.value };
    case ServiceUnavailable.NOTIFY_FIVEG:
      return { ...state, notifyfiveG: action.value };
    case ServiceUnavailable.RESTORE_DEFAULTS:
      return initialState;
    default:
      return state;
  }
};

export default notifyReducer;

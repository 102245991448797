import axios from 'axios';
import loadjs from 'loadjs';
import React from 'react';
import api from '../services/apiGateway';
import { Flowtype, MethodType, Pages } from '../constants/common';
import { apiUrl, getDomainUrl, isProd, prodEspanolDomain } from '../constants/api-urls';
import { lqServices, platforms, hereMapScript } from '../constants/lq-constants';
import { checkAuthFlow } from '../components/address-qualification/actions';
import { LoopQualification as lq_Constant } from '../constants/actionTypes';
import { updatePlansPage, updateCurrentView } from '../components/loop-qual-app/actions';
import { logMetrics } from '../services/logService';
import { setVendorCookie, callValidateAPI } from '../components/order-fios/actions';
import { idmeLib } from '../services/idme';
import Button from '../components/common/Button';
import { dataTrack } from './vzdl';
import { getSourceInfo, getTwsFlow } from '../config';

const apiKey = apiUrl().locusapikey;
const locusIdApikey = apiUrl().locusIdApiKey;
const { CancelToken } = axios;
const oldRequestCancelled = 'old Request Cancelled';
const TOKEN_BEARER = 'Bearer ';
const APPLICATION_JSON = 'application/json';
const locusApiAddresLookup = 'locus-';
const nsaApi = 'soe/digital';
const Q_PARM = '?';
let tokenDuration = 0;
let tokenUpdatedTime = 0;
let tokenValidUpto = 0;
let currentRequest = { cancel: null, url: null };

export function getCookie(cname) {
  try {
    const cookies = document.cookie;
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(cookies);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  } catch (e) {
    return null;
  }
}

export function filterByContains(filterKey, filterVal, collection) {
  if (!filterVal) {
    return collection;
  }
  // eslint-disable-next-line no-prototype-builtins
  if (!collection || !collection.length || !collection[0].hasOwnProperty(filterKey)) {
    return [];
  }
  return collection.filter((item) => item[filterKey].indexOf(filterVal) > -1);
}
export function getGlobalSessionID(cookies) {
  try {
    let gsmID = '';
    const gsmKey = 'visitor_id';
    if (typeof cookies === 'string') {
      if (cookies.indexOf(gsmKey) !== -1) {
        const slicedToken = typeof cookies === 'string' ? cookies.slice(cookies.indexOf(gsmKey) + gsmKey.length) : '';
        gsmID = slicedToken && slicedToken.indexOf(';') > -1 ? slicedToken.slice(0, slicedToken.indexOf(';')) : '';
        return gsmID !== '' && typeof gsmID === 'string' ? gsmID.split('=')[1] : gsmID;
      }
    }
    return gsmID;
  } catch (e) {
    return null;
  }
}
export function getReqHeader(url, methodType, data, contentType, reqHeader, reqGlobalSession, digiSession = null) {
  try {
    let requestObj = null;
    if (reqHeader && reqHeader.apiKey) {
      if (currentRequest.url && currentRequest.url === url.slice(0, url.indexOf(Q_PARM)) && currentRequest.cancel) {
        currentRequest.cancel(oldRequestCancelled);
      }
      currentRequest = { url: url.slice(0, url.indexOf(Q_PARM)), cancel: null };
    }
    if (reqHeader && reqHeader.emailCancel === 'Y') {
      if (currentRequest.url && currentRequest.cancel) {
        currentRequest.cancel(oldRequestCancelled);
      }
      currentRequest = { url, cancel: null };
    }
    requestObj = {
      url,
      headers: {
        'Content-Type': contentType === '' ? APPLICATION_JSON : contentType,
      },
      method: methodType,
      responseType: 'json',
      cache: false,
      data,
      crossDomain: true,
      visitor_id: reqGlobalSession ? getGlobalSessionID(document.cookie) : '',
      cancelToken: new CancelToken((cancelFunction) => {
        currentRequest.cancel = cancelFunction;
      }),
      timeout: 90000,
    };
    if (reqHeader) {
      if (reqHeader.authToken) {
        const authorization = TOKEN_BEARER + getFromStorage('token');
        requestObj.headers.Authorization = authorization;
      }
      if (reqHeader.apiKey) {
        if ((url && url.indexOf('getByLocationIds') > -1) || (url && url.indexOf('by-lat-long') > -1)) {
          requestObj.headers.apikey = locusIdApikey;
        } else {
          requestObj.headers.apikey = apiKey;
        }
      }
      if (url && (url.indexOf(nsaApi) > -1 || url.indexOf('inhome/check5gavailability') > -1 || url.indexOf('inhome/updateCartFWA') > -1)) {
        requestObj.headers.flowName = reqHeader && reqHeader.flowType ? reqHeader.flowType : getCookie('loggedIn') ? 'AAL' : 'NSE';
        requestObj.headers['from-referrer'] = window.location.href;
        requestObj.headers.fromReferrer = window.location.href;
        requestObj.headers.channelId = getTaggingChannelId();
        if (getCookie('SOE-XSRF-TOKEN-SALES')) {
          requestObj.headers['X-XSRF-TOKEN'] = getCookie('SOE-XSRF-TOKEN-SALES');
        } else if (getCookie('XSRF-TOKEN')) {
          requestObj.headers['X-XSRF-TOKEN'] = getCookie('XSRF-TOKEN');
        }
      }
      if (digiSession && url && url.indexOf('combo') > -1) {
        requestObj.headers.digital_ig_session = digiSession;
      }
    }

    if (url && url.indexOf(locusApiAddresLookup) === -1) {
      // requestObj.headers['Pragma'] = NO_CACHE;
      // requestObj.headers['Cache-Control'] = NO_STORE;
      requestObj.withCredentials = true;
    }

    return requestObj;
  } catch (e) {
    return null;
  }
}
export const genarateToken = () =>
  new Promise((resolve) => {
    const url = apiUrl().generateToken;
    api(getReqHeader(url, MethodType.GET, '', APPLICATION_JSON))
      .then((response) => {
        if (response && response.data) {
          processTokenResponse(response, resolve);
        }
      })
      .catch((error) => {
        resolve(error);
      });
  });
export const invokeVisitorID = (resolve) => {
  try {
    const visitidUrl = apiUrl().generatevisitorid;
    api(getReqHeader(visitidUrl, MethodType.GET, '', APPLICATION_JSON, { authToken: true })).then((response) => {
      const { data } = response;
      if (data && data.visitor_id) {
        window.localStorage.setItem('visitor_id', data.visitor_id);
        setToStorage('visitor_id', data.visitor_id);
        resolve(response);
      }
    });
  } catch (error) {
    console.log(error);
  }
};
export const generateVisitorId = () => {
  try {
    return new Promise((resolve) => {
      if (!checkTokenValidity()) {
        genarateToken()
          .then((resp) => {
            if (resp.status === 200) {
              invokeVisitorID(resolve);
            }
          })
          .catch((error) => {
            logMetrics('generatevisitorid', error.message, 'Error');
          });
      } else {
        invokeVisitorID(resolve);
      }
    });
  } catch (ex) {
    logMetrics('generatevisitorid', ex.message, 'Error');
  }
};
export const invokeVisitID = (resolve) => {
  try {
    const visitidUrl = apiUrl().generatevisitId;
    api(getReqHeader(visitidUrl, MethodType.GET, '', APPLICATION_JSON, { authToken: true })).then((response) => {
      const { data } = response;
      if (data && data.visit_id) {
        window.localStorage.setItem('visit_id', data.visit_id);
        window.localStorage.setItem('visitor_id', data.visitor_id);
        window.localStorage.setItem('expirationTime', data.expirationTime);
        setToStorage('expirationTime', data.expirationTime);
        setToStorage('visit_id', data.visit_id);
        setToStorage('visitor_id', data.visitor_id);
        resolve(response);
      }
    });
  } catch (error) {
    console.log(error);
  }
};
export const generateVisitId = () => {
  try {
    return new Promise((resolve) => {
      if (!checkTokenValidity()) {
        genarateToken()
          .then((resp) => {
            if (resp.status === 200) {
              invokeVisitID(resolve);
            }
          })
          .catch((error) => {
            logMetrics('generatevisitid', error.message, 'Error');
          });
      } else {
        invokeVisitID(resolve);
      }
    });
  } catch (ex) {
    logMetrics('generatevisitid', ex.message, 'Error');
  }
};
export const validateVendorToken = async (vToken, dispatch) => {
  try {
    return new Promise(async (resolve) => {
      if (!checkTokenValidity()) {
        genarateToken()
          .then(async (resp) => {
            if (resp.status === 200) {
              await invokeValidateVendorToken(vToken, dispatch, resolve);
            }
          })
          .catch((error) => {
            logMetrics('generatevisitid', error.message, 'Error');
          });
      } else {
        await invokeValidateVendorToken(vToken, dispatch, resolve);
      }
    });
  } catch (ex) {
    logMetrics('generatevisitid', ex.message, 'Error');
  }
};
const invokeValidateVendorToken = async (vtokenValue, dispatch, resolve) => {
  try {
    const vTokenUrl = apiUrl().vendorFlowToken;
    api(getReqHeader(vTokenUrl, MethodType.POST, JSON.stringify({ vtoken: vtokenValue }), APPLICATION_JSON, { authToken: true })).then(
      async (response) => {
        if (response && response.data && response.data.data) {
          const { tokenvalidated, visitId, visitorId } = response.data.data;
          if (tokenvalidated) {
            window.localStorage.setItem('token_Validated', tokenvalidated);
            setToStorage('token_Validated', tokenvalidated);
            await setVendorCookie(visitId, visitorId, dispatch);
          }
          resolve(response);
        }
      },
    );
  } catch (error) {
    console.log(error);
  }
};
export function processTokenResponse(response, resolve) {
  tokenDuration = parseInt(response.data.expires_in) * 1000 || 0; // conv s to mS
  const access_token_issuedAt = parseInt(response.data.issued_at);
  tokenUpdatedTime = access_token_issuedAt || tokenUpdatedTime; // epoch time in ms
  tokenValidUpto = tokenUpdatedTime + tokenDuration - 100000; // to renew new token 100s before it expires
  const access_token = response?.data?.access_token ?? null;
  setToStorage('tokenValidUpto', tokenValidUpto);
  setToStorage('token', access_token);
  resolve(response);
  const geoHdr = response.headers ? response.headers['x-ec-geohdr'] : '';
  const setPreSt =
    geoHdr &&
    geoHdr !== '' &&
    (function getGeo() {
      const stateIdx = geoHdr.indexOf('region_code='); // let ghdr = "country_code=US,region_code=,city=,dma=-1,msa=-1,lat=37.7510,long=-97.8220,zip=,continent=NA,timezone=";
      const getcountrycode = geoHdr
        .slice(0)
        .split(',')
        .reduce((final, current) => {
          const getcountrycodevalue = current.split('=');
          return typeof getcountrycodevalue === 'string' && getcountrycodevalue.length === 2
            ? { ...final, [getcountrycodevalue[0]]: getcountrycodevalue[[1]] }
            : { ...final };
        }, {});
      const countrycode = getcountrycode.country_code;
      setToStorage('countrycode', countrycode);
      if (stateIdx > 0) {
        const stateString = geoHdr.slice(stateIdx + 12);
        const userState = stateString.slice(0, 2);
        setToStorage('prefSt', userState);
      }
    })(geoHdr);
  return setPreSt;
}
export const setToStorage = (key, value) => {
  window.sessionStorage.setItem(key, value);
};
export const setToLocalStorage = (key, value) => {
  window.localStorage.setItem(key, value);
};
export const invokeHereMapScript = () => {
  loadjs(hereMapScript.mapServiceScript, { returnPromise: true })
    .then(() => {
      console.log('mapService is loaded successfully');
      loadjs(hereMapScript.mapUiScript, { returnPromise: true })
        .then(() => {
          console.log('mapUi is loaded successfully');
          loadjs(hereMapScript.mapEventsScript, { returnPromise: true })
            .then(() => {
              console.log('mapEvent is loaded successfully');
            })
            .catch(() => {
              console.log('mapEvent is loaded failed');
            });
        })
        .catch(() => {
          console.log('mapUi is loaded failed');
        });
    })
    .catch(() => {
      console.log('mapService is loaded failed');
    });

  const style = document.createElement('link');
  style.href = 'https://js.api.here.com/v3/3.1/mapsjs-ui.css';
  style.rel = 'stylesheet';
  style.text = 'text/css';
  document.head.appendChild(style);
};
export const prefetchHereMapScript = () => {
  loadjs('https://js.api.here.com/v3/3.1/mapsjs-core.js');
  loadjs('https://js.api.here.com/v3/3.1/mapsjs-harp.js');
};
export const getFromStorage = (key) => {
  const cookieValue = getCookie(key);
  return cookieValue !== '' ? cookieValue : window.sessionStorage.getItem(key);
};
export const checkTokenValidity = () => {
  if (Date.now() >= getFromStorage('tokenValidUpto')) return false;
  return true;
};
export const redirectToPlansPage = (path) => {
  const params = new URLSearchParams(window.location.search.toLowerCase());
  let replaceValue = path;
  const JTProgressiveURL = `${Pages.Progressive}?line=`;
  const FWALqAALThrottleValue = window.sessionStorage.getItem('FWALqAALThrottle');
  if (FWALqAALThrottleValue === 'BULK' && path.includes('/inhome/')) {
    window.sessionStorage.setItem('bulkPlanVisited', 'Y'); // Session Added for Bulk Revisit Scenario
  }
  if (!path?.includes(JTProgressiveURL) && sessionStorage.getItem('isJointTransaction') === 'true') {
    sessionStorage.setItem('isJointTransaction', 'false');
  } else if (!path?.includes(JTProgressiveURL) && sessionStorage.getItem('isECJointTransaction') === 'true') {
    sessionStorage.setItem('isECJointTransaction', 'false');
  }
  if (replaceValue.includes(Pages.InHomeBuildProductReskin)) {
    replaceValue = Pages.InHomeBuildProduct;
  }
  if (params.get('streamline') === 'mvp2' && (getSourceInfo() === 'HomeLQ' || params.get('smlplq') === 'y')) {
    if (replaceValue.includes('inhome')) {
      replaceValue = Pages.InHomeStreamline;
    } else if (replaceValue.includes('home/progressiveplan.html')) {
      replaceValue = Pages.SoeStreamline;
    }
    setToStorage('HomeLQMVP2', 'Y');
  } else {
    window.sessionStorage.removeItem('HomeLQMVP2');
  }
  window.location.href = replaceValue;
};
export const redirectToFiosPlansPage = (path, dispatch = null) => {
  const params = new URLSearchParams(window.location.search.toLowerCase());
  let replaceValue = path;
  if (dispatch) {
    dispatch(updatePlansPage(replaceValue));
  }
  const FWALqAALThrottleValue = window.sessionStorage.getItem('FWALqAALThrottle');
  if (FWALqAALThrottleValue === 'BULK' && path.includes('/inhome/')) {
    window.sessionStorage.setItem('bulkPlanVisited', 'Y'); // Session Added for Bulk Revisit Scenario
  }
  if (replaceValue.includes(Pages.InHomeBuildProductReskin)) {
    replaceValue = Pages.InHomeBuildProduct;
  }
  if (params.get('streamline') === 'mvp2' && (getSourceInfo() === 'HomeLQ' || params.get('smlplq') === 'y')) {
    if (replaceValue.includes('inhome')) {
      replaceValue = Pages.InHomeStreamline;
    } else {
      replaceValue = Pages.SoeStreamline;
    }
    setToStorage('HomeLQMVP2', 'Y');
  } else {
    window.sessionStorage.removeItem('HomeLQMVP2');
  }
  window.location.href = replaceValue;
};
export const redirectToInhomeTwsPage = (tws, dispatch) => {
  let redirecturl = `${Pages.inHomeTwsDiscount}&${tws}=y`;
  if (tws === 'medical_new') {
    redirecturl = `${Pages.inHomeTwsDiscount}&medical=y`;
  }
  if (window.location.host.includes('.verizonwireless.com')) {
    redirecturl = `https://www98.verizon.com${redirecturl}`;
  }
  dispatch(updatePlansPage(redirecturl));
  redirectToPlansPage(redirecturl);
};

export function redirectToMlp(fiveGQualified, qualifiedCBand, lteQualified, fiosQualified) {
  const redirectUrl = prodEspanolDomain() ? '' : getDomainUrl();
  if (fiveGQualified || qualifiedCBand) {
    if (redirectUrl && (redirectUrl === 'https://www98.verizon.com' || redirectUrl === 'https://wwwnte1aws.ebiz.verizon.com')) {
      redirectToPlansPage(`https://vzwqa2.verizonwireless.com${Pages.redirectToFiveGHome}?fromPlansLq=Y`, '_blank');
    } else {
      redirectToPlansPage(`${redirectUrl + Pages.redirectToFiveGHome}?fromPlansLq=Y`, '_blank');
    }
  } else if (lteQualified) {
    if (redirectUrl && (redirectUrl === 'https://www98.verizon.com' || redirectUrl === 'https://wwwnte1aws.ebiz.verizon.com')) {
      redirectToPlansPage(`https://vzwqa2.verizonwireless.com${Pages.redirectToLte}?fromPlansLq=Y`, '_blank');
    } else {
      redirectToPlansPage(`${redirectUrl + Pages.redirectToLte}?fromPlansLq=Y`, '_blank');
    }
  } else if (fiosQualified) {
    if (redirectUrl && (redirectUrl === 'https://www98.verizon.com' || redirectUrl === 'https://wwwnte1aws.ebiz.verizon.com')) {
      redirectToPlansPage(`https://vzwqa2.verizonwireless.com${Pages.redirectToFios}?fromPlansLq=Y`, '_blank');
    } else {
      redirectToPlansPage(`${redirectUrl + Pages.redirectToFios}?fromPlansLq=Y`, '_blank');
    }
  } else if (redirectUrl && (redirectUrl === 'https://www98.verizon.com' || redirectUrl === 'https://wwwnte1aws.ebiz.verizon.com')) {
    redirectToPlansPage(`https://vzwqa2.verizonwireless.com${Pages.redirectToHomeHub}?fromPlansLq=Y`, '_blank');
  } else {
    redirectToPlansPage(`${redirectUrl + Pages.redirectToHomeHub}?fromPlansLq=Y`, '_blank');
  }
}

export const checkLoggedInStatus = (dispatch, loopQualApp) => {
  const loggedInCookie = getCookie('loggedIn');
  let isLoggedIn = false;
  let roleType = '';
  if (loggedInCookie === 'true') {
    isLoggedIn = true;
    roleType = getCookie('role');
  }
  if ((loopQualApp.roleType === 'prepay' || loopQualApp.roleType === 'guest') && (roleType !== 'prepay' || roleType !== 'guest')) {
    dispatch(updateCurrentView('ADDRESS_QUALIFICATION'));
  }
  dispatch({ type: lq_Constant.UPDATE_LOGGED_IN, value: isLoggedIn });
  dispatch({ type: lq_Constant.ROLE_TYPE, value: roleType });
  return isLoggedIn;
};
export const checkLoggedInStatusUserInfo = (dispatch) => {
  let loginStatus = false;
  const loginInfo = getCookie('userinfo');
  if (loginInfo !== undefined && loginInfo != null && loginInfo !== '') {
    loginStatus = true;
  } else if (window.location.search && window.location.search.includes('ismvflow=y&fromTestPage=Y')) {
    const vCustId = getCookie('vCustId');
    const vAcctId = getCookie('vAcctId');
    if (vCustId && vAcctId) {
      loginStatus = true;
    }
  }
  dispatch({ type: lq_Constant.UPDATE_LOGGED_IN_USERINFO, value: loginStatus });
  return loginStatus;
};
export const checkFiosVzt = (dispatch) => {
  let isFiosVzt = false;
  const isvzt = getCookie('islogin');
  if (isvzt) {
    logMetrics('loginType', isvzt, 'Info');
    if (isvzt === 'vzt') {
      isFiosVzt = true;
    }
  }
  dispatch({ type: lq_Constant.FIOS_VZT, value: isFiosVzt });
  return isFiosVzt;
};
export const checkLoggedIn = (dispatch, cookies, loopQualApp, addressInfo, addressQualification, moveDetails) => {
  checkAuthFlow(dispatch, cookies, loopQualApp, addressInfo, addressQualification, moveDetails);
};
export const getQualifiedService = (lqResult) => {
  let qualifiedService = '';
  if (lqResult) {
    qualifiedService = lqResult.fiosQualified
      ? lqServices.fios
      : lqResult.fiveGQualified || lqResult.qualifiedCBand || lqResult.tgwQualified
      ? lqServices.fiveg
      : lqResult.lteQualified
      ? lqServices.lte
      : lqServices.all;
  }
  return qualifiedService;
};
export const getMVServiceName = (service) =>
  service.includes('mmw') || service.includes('cbd') || service.includes('tgw')
    ? lqServices.fiveg
    : service.includes('lte')
    ? lqServices.lte
    : service.includes('fios')
    ? lqServices.fios
    : '';
export const getServiceName = (service) =>
  service === 'LTE'
    ? lqServices.lte
    : service === 'FIVEG' || service === '5G'
    ? lqServices.fiveg
    : service === 'FIOS'
    ? lqServices.fios
    : lqServices.all;

export const getServiceSubText = (service) =>
  service === 'LTE'
    ? 'Up to 50 Mbps download speeds.<br/>Typical speeds of 25 Mbps.'
    : service === 'FIVEG' || service === '5G'
    ? 'Up to Gigabit download speeds.<br/>Typical speeds of 300 Mbps.'
    : service === 'FIOS'
    ? ''
    : '';

export const getPlatformName = (service) => platforms[service];

const isMobile = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (
    userAgent &&
    (userAgent.match(/android/i) ||
      userAgent.match(/webos/i) ||
      userAgent.match(/iphone/i) ||
      userAgent.match(/blackberry/i) ||
      userAgent.match(/windows phone/i) ||
      userAgent.match(/my_vzw_app/i))
  ) {
    return true;
  }
  return false;
};

export const isMYVZWAPP = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent && userAgent.match(/my_vzw_app/i)) {
    return true;
  }
  return false;
};
// const isTablet = () => {
//   const userAgent = window.navigator.userAgent.toLowerCase();
//   if (
//     userAgent && (userAgent.match(/tablet/i)
// || userAgent.match(/ipad/i))
//   ) {
//     return true;
// }
// return false;
// };

const viewport = () => {
  if (isMobile()) {
    return 'mobile';
  }
  return 'desktop';
};
export const getTaggingChannelId = () => {
  let channelId = 'VZW-DOTCOM';
  if (viewport() === 'mobile' || viewport() === 'mfapp') {
    if (window.navigator.userAgent.indexOf('VZW-MFA-IOS') > -1) {
      channelId = 'VZW-MFA-IOS';
    } else if (window.navigator.userAgent.indexOf('VZW-MFA-ANDROID') > -1) {
      channelId = 'VZW-MFA-ANDROID';
    } else {
      channelId = 'VZW-DOTCOM-MOB';
    }
  }
  setToStorage('channelId', channelId);
  return channelId;
};
export function getWidgetHost(pathName) {
  const plansvisit = window?.vzwlqwidgetconfig?.sourceInfo === 'plansvisit';
  const TWSPages = ['military', 'featured/nurses', 'featured/students', 'featured/teachers', 'featured/first-responders'];
  const isTWSPage = TWSPages?.some((item) => pathName.includes(item));
  if (pathName.includes('5g') || pathName.includes('plans/unlimited') || pathName.includes('vfw/retail/homeinternet')) {
    if (!plansvisit) {
      window.sessionStorage.setItem('widgetHost', '5G');
    }
    return '5g';
  }
  if (pathName.includes('lte')) {
    if (!plansvisit) {
      window.sessionStorage.setItem('widgetHost', 'LTE');
    }
    return 'lte';
  }
  if (pathName.includes('fios') || pathName.includes('inhome/checkavailability?PN') || isTWSPage) {
    if (!plansvisit) {
      window.sessionStorage.setItem('widgetHost', 'Fios');
    }
    return 'fios';
  }
  if (pathName.includes('wi-fi-backup')) {
    if (!plansvisit) {
      window.sessionStorage.setItem('widgetHost', 'WIFIBACKUP');
    }
    return 'wifibackup';
  }
  if (!plansvisit) {
    window.sessionStorage.removeItem('widgetHost');
  }

  window.sessionStorage.setItem(
    'widgetLQHost',
    pathName.includes('streamline') || window.location.search.includes('streamline=mvp2') ? 'Streamline' : '',
  );

  return 'all';
}

export function getSignInIframeUrl(lqResult, couponLQ, loopQualApp, sessionTimeout) {
  if (sessionTimeout?.sessionExpired) {
    // const urlSearchParams = new URLSearchParams(window.location.search);
    // const customUrl = urlSearchParams.get('customurl');
    const multiParams = window.location.href.indexOf('?') > -1;
    const isQa = window.location.href.indexOf('vzwqa') > -1 || window.location.href.indexOf('wwwnssit') > -1;
    const currentURL = window.location.href;
    let signinAuth = apiUrl().signIn_auth;
    if (isQa) {
      const currentDomain = currentURL.match(/\d+/g);
      if (currentDomain?.length > 0) {
        signinAuth = signinAuth.replace(/\d+/, currentDomain[0]);
      }
    }
    return signinAuth + encodeURIComponent(`${window.location.href}${multiParams ? '&session_retry=Y' : '?session_retry=Y'}`);
  }
  const XX_Service = getQualifiedService(lqResult);
  if ((XX_Service === lqServices.fiveg || XX_Service === lqServices.lte) && !loopQualApp.isACPFlow) {
    switch (window.location.host) {
      case 'vzwqa1.verizonwireless.com':
        return Pages.signIn_WirelessQa1Nsa;

      case 'vzwqa2.verizonwireless.com':
        return Pages.signIn_WirelessQa2Nsa;

      case 'vzwqa3.verizonwireless.com':
        return Pages.signIn_WirelessQa3Nsa;

      case 'vzwqa4.verizonwireless.com':
        return Pages.signIn_WirelessQa4Nsa;

      case 'vzwqa5.verizonwireless.com':
        return Pages.signIn_WirelessQa5Nsa;

      case 'vzwqa6.verizonwireless.com':
        return Pages.signIn_WirelessQa6Nsa;

      case 'vzwqa7.verizonwireless.com':
        return Pages.signIn_WirelessQa7Nsa;

      case 'vzwqa10.verizonwireless.com':
        return Pages.signIn_WirelessQa10Nsa;

      case 'vzwqa30.verizonwireless.com':
        return Pages.signIn_WirelessQa30Nsa;

      case 'vzwqa40.verizonwireless.com':
        return Pages.signIn_WirelessQa40Nsa;

      case 'vzwqa50.verizonwireless.com':
        return Pages.signIn_WirelessQa50Nsa;

      case 'vzwqa65.verizonwireless.com':
        return Pages.signIn_WirelessQa65Nsa;

      case 'wwwnssit5.verizon.com':
        return Pages.signIn_WirelessNSSIT5Nsa;

      case 'wwwnssit6.verizon.com':
        return Pages.signIn_WirelessNSSIT6Nsa;

      case 'wwwnssit5.ebiz.verizon.com':
        return Pages.signIn_WirelessNSSIT_EBIZ5Nsa;

      default:
        return apiUrl().signIn_WirelessNSANew;
    }
  }
  if (loopQualApp.TWS) {
    const authType = window.location.search.indexOf('?') > -1 ? '&auth=Y' : '?auth=Y';
    return apiUrl().signIn_auth + encodeURIComponent(`${window.location.href}${authType}`);
  }
  /*eslint-disable */
  if (couponLQ) {
    if (window.location.host === 'vzwqa1.verizonwireless.com') {
      return `https://securesit1.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https://vzwqa1.verizonwireless.com/sales/home/external/checkAvailability.html?auth=Y&PN=${couponLQ}`;
    } else if (window.location.host === 'vzwqa2.verizonwireless.com') {
      return `https://securesit2.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https://vzwqa2.verizonwireless.com/sales/home/external/checkAvailability.html?auth=Y&PN=${couponLQ}`;
    } else if (window.location.host === 'vzwqa3.verizonwireless.com') {
      return `https://securesit3.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https://vzwqa3.verizonwireless.com/sales/home/external/checkAvailability.html?auth=Y&PN=${couponLQ}`;
    } else if (window.location.host.includes('ebiz.verizon.com')) {
      return `https://securesit1.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fwwwnte1aws.ebiz.verizon.com%2Finhome%2Fcheckavailability%3FPN%${couponLQ}%26skip5g%3Dy%26auth%3Dy`;
    } else {
      return apiUrl().signInConnection + couponLQ;
    }
  }
  if (
    loopQualApp.flowType === Flowtype.MOVERS ||
    (window.location.search &&
      (window.location.search.toLowerCase().indexOf('move=y') > -1 || window.location.search.toLowerCase().indexOf('type=moving') > -1))
  ) {
    if (window.location.search && window.location.search.toLowerCase().indexOf('type=moving') > -1 && isProd()) {
      const windowURL = window.location.href.replace(/#.*/g, '');
      return apiUrl().signIn_auth + encodeURIComponent(`${windowURL}&auth=Y`);
    }
    switch (window.location.host) {
      case 'vzwqa1.verizonwireless.com':
        return Pages.signIn_WirelessQa1NsaMV;

      case 'vzwqa2.verizonwireless.com':
        return Pages.signIn_WirelessQa2NsaMV;

      case 'vzwqa3.verizonwireless.com':
        return Pages.signIn_WirelessQa3NsaMV;

      case 'wwwnssit5.verizon.com':
        return Pages.signIn_WirelessNSSIT5NsaMV;

      case 'wwwnssit6.verizon.com':
        return Pages.signIn_WirelessNSSIT6NsaMV;

      case 'wwwnssit5.ebiz.verizon.com':
        return Pages.signIn_WirelessNSSIT_EBIZ5NsaMV;

      default:
        return apiUrl().signIn_WirelessNSAMVNew;
    }
  }
  if (loopQualApp.isACPFlow) {
    let auth = window.location.search.indexOf('?') > -1 ? '&auth=Y' : '?auth=Y';
    auth = XX_Service === lqServices.fiveg || XX_Service === lqServices.lte ? `${auth}&vfmnh=y` : auth;
    let url = window.location.href;
    if (url.indexOf('verizon-forward/#checkAvailability') > -1) {
      url = url.replace('#checkAvailability', '');
    }
    switch (window.location.host) {
      case 'vzwqa1.verizonwireless.com':
        return Pages.signIn_authQa1 + encodeURIComponent(`${url + auth}`);
      case 'vzwqa2.verizonwireless.com':
        return Pages.signIn_authQa2 + encodeURIComponent(`${url + auth}`);
      case 'vzwqa3.verizonwireless.com':
        return Pages.signIn_authQa3 + encodeURIComponent(`${url + auth}`);
      case 'wwwnssit5.verizon.com':
        return Pages.signIn_VFSit5 + encodeURIComponent(`${url + auth}`);
      case 'wwwnssit6.verizon.com':
        return Pages.signIn_VFSit6 + encodeURIComponent(`${url + auth}`);
      case 'wwwnssit5.ebiz.verizon.com':
        return Pages.signIn_VFEbiz5 + encodeURIComponent(`${url + auth}`);
      default:
        return apiUrl().signIn_auth + encodeURIComponent(`${url + auth}`);
    }
  }
  if (XX_Service === lqServices.fios && !loopQualApp?.isComboEmailflow) {
    switch (window.location.host) {
      case 'vzwqa1.verizonwireless.com':
        return `${Pages.signIn_WirelessQa1NSAtoFios}visit_id%3D${lqResult.visit_id}%26visitor_id%3D${lqResult.visitor_id}`;

      case 'vzwqa2.verizonwireless.com':
        return `${Pages.signIn_WirelessQa2NSAtoFios}visit_id%3D${lqResult.visit_id}%26visitor_id%3D${lqResult.visitor_id}`;

      case 'vzwqa3.verizonwireless.com':
        return `${Pages.signIn_WirelessQa3NSAtoFios}visit_id%3D${lqResult.visit_id}%26visitor_id%3D${lqResult.visitor_id}`;

      case 'wwwnte1aws.ebiz.verizon.com':
        return `${Pages.signIn_WirelessNSAtoFiosNte1}`;

      case 'www98.verizon.com':
        return `${Pages.signIn_WirelessNSAtoFiosStg}`;

      case 'wwwawssit.ebiz.verizon.com':
        return `${Pages.signIn_WirelessNSAtoFiosSit}`;

      case 'preprod-east-gz.vpc.verizon.com':
        return `${Pages.signIn_WirelessNSAtoFiosPP}`;

      default:
        return `${Pages.signIn_WirelessNSAtoFios}`;
    }
  }
  if (loopQualApp?.isComboEmailflow) {
    switch (window.location.host) {
      case 'vzwqa1.verizonwireless.com':
        return `${Pages.signIn_WirelessQa1NSAtoFiosComboEmail}visit_id%3D${lqResult.visit_id}%26visitor_id%3D${lqResult.visitor_id}`;

      case 'vzwqa2.verizonwireless.com':
        return `${Pages.signIn_WirelessQa2NSAtoFiosComboEmail}visit_id%3D${lqResult.visit_id}%26visitor_id%3D${lqResult.visitor_id}`;

      case 'vzwqa3.verizonwireless.com':
        return `${Pages.signIn_WirelessQa3NSAtoFiosComboEmail}visit_id%3D${lqResult.visit_id}%26visitor_id%3D${lqResult.visitor_id}`;

      case 'wwwnte1aws.ebiz.verizon.com':
        return `${Pages.signIn_WirelessNSAtoFiosNte1}`;

      case 'www98.verizon.com':
        return `${Pages.signIn_WirelessNSAtoFiosStg}`;

      case 'wwwawssit.ebiz.verizon.com':
        return `${Pages.signIn_WirelessNSAtoFiosSit}`;

      case 'preprod-east-gz.vpc.verizon.com':
        return `${Pages.signIn_WirelessNSAtoFiosPP}`;

      default:
        return `${Pages.signIn_WirelessNSAtoFios}`;
    }
  }
  return apiUrl().signInNew;
}

export const getInHomeTWSURL = (loopQualApp) => {
  if (loopQualApp.TWS) {
    const preTWSUrl = '&source=urc&customerintent=ACCORDION&subflowtype=accordion&';
    const postTWSUrl = '=y&EnableSmartcart=y&enableinhomesmartcart=y';
    let mfrtScope = '';
    if (loopQualApp.TWS === 'milvet') {
      mfrtScope = 'military';
    } else {
      mfrtScope = loopQualApp.TWS;
    }
    return getDomainUrl() + Pages.inHomeChangeSignin + preTWSUrl + mfrtScope + postTWSUrl;
  }
  return false;
};

export const callSkipYMethod = () => {
  callValidateAPI();
  idmeLib();
};

export const idMeButton = (loopQualApp, fromDiscountMnh, callSkipY, secondary, twsTeacherStickyLq, btnText, addressQualification) => {
  let tws = loopQualApp.TWS ? (loopQualApp.TWS === 'medical_new' ? 'medical' : loopQualApp.TWS) : '';
  if (tws && tws === 'milvet') {
    tws = 'military';
  }
  const isNonProd = !!(
    window.location.host === 'www98.verizon.com' ||
    window.location.host === 'wwwawssit.ebiz.verizon.com' ||
    window.location.host === 'www98v05g-aem-east-yz.ebiz.verizon.com' ||
    window.location.host === 'vzwqa1.verizonwireless.com' ||
    window.location.host === 'vzwqa2.verizonwireless.com' ||
    window.location.host === 'vzwqa3.verizonwireless.com'
  );

  let redirectionUrl = '';
  if (window.location.host.indexOf('.verizonwireless.com') > -1) {
    redirectionUrl = `https%3A%2F%2Fwww98.verizon.com%2Finhome%2Fbuildproducts%3Flq2%3Dy%26type%3D${tws}%26visitor_id%3D${addressQualification?.lqResult?.visitor_id}%26visit_id%3D${addressQualification?.lqResult?.visit_id}`;
  } else {
    redirectionUrl = `https%3A%2F%2Fwww98.verizon.com%2Finhome%2Fbuildproducts%3Flq2%3Dy%26type%3D${tws}`;
  }

  return (
    <React.Fragment>
      <Button
        onClick={callSkipY ? callSkipYMethod : idmeLib}
        use={secondary ? 'secondary' : 'primary'}
        data-scope={tws}
        id="idme-wallet-button"
        data-client-id={isNonProd ? '1060a09c1e29a70e42bd1d73cbb08a30' : 'd81cec8ab65a39af8915db0f44e11c27'}
        data-response="code"
        data-redirect={isNonProd ? redirectionUrl : `https%3A%2F%2Fwww.verizon.com%2Finhome%2Fbuildproducts%3Flq2%3Dy%26type%3D${tws}`}
        data-op="signin"
        data-display={isMYVZWAPP() ? 'isMVA' : 'popup'}
        data-show-verify="true"
        data-track={dataTrack('link', fromDiscountMnh || loopQualApp.isACPFlow || twsTeacherStickyLq ? 'Continue' : 'Check for discount')}
      >
        {btnText || (fromDiscountMnh || loopQualApp.isACPFlow || twsTeacherStickyLq ? 'Continue' : 'Check for discount')}
      </Button>
    </React.Fragment>
  );
};

export const queryStringToJson = (queryString) => {
  // Split the query string into an array of key-value pairs.
  const keyValuePairs = queryString.split('&');

  // Create an empty object to store the key-value pairs.
  const result = {};

  // Iterate over the key-value pairs.
  for (const keyValuePair of keyValuePairs) {
    // Split the key-value pair into a key and a value.
    const [key, value] = keyValuePair.split('=');

    // Add the key-value pair to the result object.
    result[key] = value;
  }

  // Return the result object.
  return result;
};

export const getLoggedInTwsLQ = () => {
  const params = new URLSearchParams(window.location.search);
  const isLoggedIn = getCookie('loggedIn') === 'true' || getCookie('userinfo');
  const twsSrcInfo = getSourceInfo();
  let twsFlow = getTwsFlow() ? getTwsFlow() : params.get('type') ? params.get('type') : null;
  if (
    (twsFlow &&
      (twsFlow === 'medical' ||
        twsFlow === 'student' ||
        twsFlow === 'medical_new' ||
        twsFlow === 'responder' ||
        twsFlow === 'teacher' ||
        twsFlow === 'milvet' ||
        twsFlow === 'nurse')) ||
    (twsSrcInfo && twsSrcInfo.indexOf('tws') > -1)
  ) {
    if (twsSrcInfo && twsSrcInfo.indexOf('tws') > -1) {
      twsFlow = twsSrcInfo.replace('tws_', '');
    }
    if (twsFlow === 'medical') {
      twsFlow = 'medical_new';
    }
    return isLoggedIn && twsFlow;
  }
  return false;
};

export const wifiBackupQueryParam = window.location.search && window.location.search.toLowerCase().indexOf('wifibackup=y') > -1;

export const wifiBackupFromFios = window.location.search && window.location.search.toLowerCase().indexOf('wifibackupfios=y') > -1;

export const isWifiBackupMLP = window?.vzwlqwidgetconfig?.source === 'WIFIBACKUP';

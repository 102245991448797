import { ServiceUnavailable } from '../../constants/actionTypes';
import { apiUrl } from '../../constants/api-urls';
import callingApi from '../../services/apiService';
import { MethodType } from '../../constants/common';
import { logMetrics } from '../../services/logService';

import { checkTokenValidity, genarateToken } from '../../utils/lqServiceUtil';

export const call5GServiceUnavailable = (addressInfo, smsConsentShow, checkboxchecked) => {
  const url = apiUrl().getNotify;
  let requestPayLoad = '';
  if (smsConsentShow && checkboxchecked) {
    requestPayLoad = {
      emailId: addressInfo.emailId,
      phoneNumber: addressInfo.phoneNumber,
      address: addressInfo.address,
      superBowlPromo: false,
      consentPurpose: 'Transactional',
    };
  } else {
    requestPayLoad = {
      emailId: addressInfo.emailId,
      phoneNumber: addressInfo.phoneNumber,
      address: addressInfo.address,
      superBowlPromo: false,
    };
  }

  const location =
    window.location.search &&
    (window.location.search.indexOf('testQualification') > -1 ||
      window.location.search.indexOf('nsa=y') > -1 ||
      window.location.search.indexOf('NSA=Y') > -1)
      ? 'Y'
      : null;
  let flowName;
  if (location && location === 'Y') {
    flowName = 'test|';
  }
  const requiredflowType = { flowType: flowName };
  return new Promise((resolve, reject) => {
    if (wirelineApiSkipInQALowerEnv()) {
      resolve(true);
    }
    callingApi(url, MethodType.POST, requestPayLoad, 'application/json', requiredflowType)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        logMetrics('call5GServiceUnavailable', error.message, 'Error');
      });
  });
};

export const calladdSmsConsnet = (addressInfo, widgetHost, spid) => {
  const url = apiUrl().addSMSConsent;
  const location =
    window.location.search &&
    (window.location.search.indexOf('testQualification') > -1 ||
      window.location.search.indexOf('nsa=y') > -1 ||
      window.location.search.indexOf('NSA=Y') > -1)
      ? 'Y'
      : null;
  let flowName;
  if (location && location === 'Y') {
    flowName = 'test|';
  }
  const requiredflowType = { flowType: flowName };
  const requestPayLoad = {
    mtn: addressInfo.phoneNumber,
    pageName: 'Loop Qual',
    product: widgetHost,
    chkBox: 'Checked',
    vzPurpose: 'Transactional',
    lineOfBusiness: 'Wireless',
    spid,
  };
  return new Promise((resolve, reject) => {
    callingApi(url, MethodType.POST, requestPayLoad, 'application/json', requiredflowType)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        logMetrics('calladdSmsConsnet', error.message, 'Error');
      });
  });
};

export const callPushMessage = (addressInfo) => {
  const url = apiUrl().getNotify;
  const requestPayLoad = {
    emailId: addressInfo.emailId,
    phoneNumber: addressInfo.phoneNumber,
    address: addressInfo.address,
    superBowlPromo: false,
  };

  const location =
    window.location.search &&
    (window.location.search.indexOf('testQualification') > -1 ||
      window.location.search.indexOf('nsa=y') > -1 ||
      window.location.search.indexOf('NSA=Y') > -1)
      ? 'Y'
      : null;
  let flowName;
  if (location && location === 'Y') {
    flowName = 'test|';
  }
  const requiredflowType = { flowType: flowName };
  return new Promise((resolve, reject) => {
    if (wirelineApiSkipInQALowerEnv()) {
      resolve(true);
    }
    callingApi(url, MethodType.POST, requestPayLoad, 'application/json', requiredflowType)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        logMetrics('callPushMessage', error.message, 'Error');
      });
  });
};
export const callFutureNotification = (addressInfo, notify, customerStreetDetails, customerUnitDetails) => {
  if (!checkTokenValidity()) {
    genarateToken()
      .then((resp) => {
        if (resp.status === 200) {
          InvokeFutureNotification(addressInfo, notify, customerStreetDetails, customerUnitDetails);
        }
      })
      .catch((error) => {
        logMetrics('invokeNtasStreetToken', error.message, 'Error');
      });
  } else {
    InvokeFutureNotification(addressInfo, notify, customerStreetDetails, customerUnitDetails);
  }
};
export const InvokeFutureNotification = (addressInfo, notify, customerStreetDetails, customerUnitDetails) => {
  let addrId = '';
  if (customerUnitDetails && customerUnitDetails.ntasAddrId) {
    addrId = customerUnitDetails.ntasAddrId.toString();
  } else if (customerStreetDetails && customerStreetDetails.addressID) {
    addrId = customerStreetDetails.addressID.toString();
  } else if (customerStreetDetails.ntasAddrID) {
    addrId = customerStreetDetails.ntasAddrID.toString();
  }
  const requiredAuthtoken = { authToken: true };
  const data = {
    telNbr: addressInfo.phoneNumber,
    firstName: '',
    lastName: '',
    businessName: '',
    addressLine1: addressInfo.address && addressInfo.address.address1 ? addressInfo.address.address1 : '',
    addressLine2: addressInfo.address && addressInfo.address.address2 ? addressInfo.address.address2 : '',
    city: addressInfo.address && addressInfo.address.city ? addressInfo.address.city : '',
    state: addressInfo.address && addressInfo.address.state ? addressInfo.address.state : '',
    zipCode: addressInfo.address && addressInfo.address.zipcode ? addressInfo.address.zipcode : '',
    emailAddress: addressInfo.emailId,
    contactPhone: addressInfo.phoneNumber,
    vzPhone: '',
    notifyFios: notify.notifyFios ? 'Y' : 'N',
    notifyFiosTv: notify.notifyFiosTv ? 'Y' : 'N',
    appId: 'NC',
    nsiInd: customerStreetDetails.propertyType && customerStreetDetails.propertyType === 'B' ? 'B' : 'R',
    addrId,
  };
  const api = apiUrl().futureNotification;
  callingApi(api, MethodType.POST, data, '', requiredAuthtoken)
    .then(() => {})
    .catch((error) => {
      logMetrics('InvokeFutureNotification', error.message, 'Error');
    });
};
export const retrieveCustomerConsent = (formattedNumber, dispatch) => {
  try {
    let flowName;
    const location =
      window.location.search &&
      (window.location.search.indexOf('testQualification') > -1 ||
        window.location.search.indexOf('nsa=y') > -1 ||
        window.location.search.indexOf('NSA=Y') > -1)
        ? 'Y'
        : null;
    if (location && location === 'Y') {
      flowName = 'test|';
    }
    const requiredflowType = { flowType: flowName };
    const request = {
      data: {
        mtn: formattedNumber,
        purposeName: 'Transactional',
        lineOfBusiness: 'Wireless',
        flowName: 'Loop Qual',
      },
    };
    return new Promise((resolve, reject) => {
      callingApi(apiUrl().getSMSConsent, MethodType.POST, request, 'application/json', requiredflowType)
        .then((response) => {
          if (response.data && response.data.data && response.data.data.spid && response.data.data.spid !== '') {
            dispatch({ type: ServiceUnavailable.SMSCONSENT_SPID, value: response.data.data.spid });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          logMetrics('retrieveCustomerConsent', error.message, 'Error');
        });
    });
  } catch (ex) {
    logMetrics('retrieveCustomerConsent', ex.message, 'Error');
  }
};

export const resetEmailAndPhone = () => ({ type: ServiceUnavailable.RESET_EMAIL_PHONE });
export const updateEmailInput = (emailaddress) => ({ type: ServiceUnavailable.EMAIL, value: emailaddress });

export const updatePhoneInput = (phonenumber) => ({ type: ServiceUnavailable.PHONE, value: phonenumber });

export const updateFormattedPhoneInput = (phonenumber) => ({ type: ServiceUnavailable.FORMATTED_PHONE, value: phonenumber });

export const emailValidFlag = (onEmailValid) => ({ type: ServiceUnavailable.EMAIL_VALID_FLAG, value: onEmailValid });

export const phoneValidFlag = (onPhoneValid) => ({ type: ServiceUnavailable.PHONE_VALID_FLAG, value: onPhoneValid });

export const setSignUpCompleted = (completionFlag) => ({ type: ServiceUnavailable.SIGN_UP_COMPLETED, value: completionFlag });

export const moveSignUpContinue = (signUpContinue) => ({ type: ServiceUnavailable.MOVE_SIGNUP_CONTINUE, value: signUpContinue });

export const RetrieveCustomerConstentTriggered = (isTriggered) => ({
  type: ServiceUnavailable.RETRIEVE_CUSTOMER_CONSENT_TRIGGERED,
  value: isTriggered,
});

export const wirelineApiSkipInQALowerEnv = () => {
  const wirelineSkipForLowerEnv =
    window.location.host.includes('awssit.ebiz.verizon.com') ||
    window.location.host.includes('nte1aws.ebiz.verizon.com') ||
    window.location.host.includes('98.verizon.com') ||
    window.location.host.includes('preprod-east-gz.vpc.verizon.com') ||
    window.location.host.includes('localhost');
  return wirelineSkipForLowerEnv;
};

import { FiveG as Action } from '../../constants/actionTypes';

const initialState = {
  reloadMap: false,
  showMap: false,
  mapCompleted: false,
  aptFlrInputCompleted: false,
};

const fiveGReducer = (state = initialState, action) => {
  switch (action.type) {
    case Action.RELOAD_MAP:
      return { ...state, reloadMap: action.value };
    case Action.SHOW_MAP:
      return { ...state, showMap: action.value };
    case Action.MAP_COMPLETED:
      return { ...state, mapCompleted: action.value };
    case Action.APT_FLR_INPUT_COMPLETED:
      return { ...state, aptFlrInputCompleted: action.value };
    case Action.RESTOREFIVEG_DEFAULTS:
      return initialState;
    default:
      return state;
  }
};

export default fiveGReducer;

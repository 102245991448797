import React, { useEffect } from 'react';
import styled from 'styled-components';
import SkeletonBox from '.';
import { configLQTheme } from '../loop-qual-app/themeConfig';

const SkeletonWrapperMinLQ = styled.div`
  display: flex;
  position: ${(props) => (props.positionEmpty ? '' : 'fixed')};
  top: 0;
  padding: 0px 30px;
  height: 112px;
  z-index: 999;
  width: 100%;
  box-sizing: border-box;
  background: ${(props) => props.bgColor || `#F3EDE0`};
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dddbdd;
  
  @media screen and (max-width: 1023px) {
    height: 173px;
  }
  .child_wrapper {
    height: auto;
    display: flex;
    align-items: flex-end;
    .hr_input_box {
      display: none;
    }
    @media screen and (max-width: 1023px) {
      flex-wrap: wrap;
      .hr_input_box {
        display: block;
        margin: 16px 0px;
      }
      width: 100%;
    }
    .first_wrapper {
      width: 270px;
    }
    .second_wrapper {
      display: flex;
      align-items: flex-end;
      .input_container {
        width: 440px;
        margin: 0px 32px 0px 45px;
        .input_box {
        }
      }
      @media screen and (max-width: 1023px) {
        width: 100%;
        margin-top: 0px;
        .input_container {
          flex: 1;
          margin-left: 0px;
        }
        .button_skeleton {
          width: 240px !important;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    height: auto !important;
    padding: 12px 20px;
    .child_wrapper {
      .hr_input_box {
        display: none;
      }
      .second_wrapper {
        flex-wrap: wrap;
        .input_container {
          margin-top: 16px;
          width: 350px;
          > div {
            &:first-child {
              width: 100% !important;
              > div {
                width: 100% !important;
                &:first-child {
                padding-right: 15px;
                }
              }
            }
          }
        }
        .button_skeleton {
          margin: 16px 0px 0px !important;
        }
      }
    }
  }
  
    @media screen and (max-width: 767px) {
    .second_wrapper{
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start!important;
    }
    @media screen and (max-width: 380px) {
    .second_wrapper{
    .input_container{
    width: 90%!important;
    }
    }
    }
`;
const MinLQSkeleton = () => {
  useEffect(() => {
    const elem = document.querySelector('#vz-gh20');
    const skeleton = document.querySelector('.skeleton_mini_lq');
    // console.log('elem', elem);
    // if (elem && elem.clientHeight && skeleton) {
    //   skeleton.style.top = elem.clientHeight + 'px';
    // }
    const headerO = new ResizeObserver((entries) => {
      //   console.log(entries, 'count');
      for (const entry of entries) {
        const cr = entry.contentRect;
        const skeletonV = document.querySelector('.skeleton_mini_lq');
        if (skeletonV) {
          skeletonV.style.top = `${cr.height}px`;
        }
      }
    });
    const skeletonO = new ResizeObserver((entries) => {
      //   console.log(entries, 'count');
      for (const entry of entries) {
        const cr = entry.contentRect;
        const mainSkeleton = document.querySelector('.lqWidgetComp');
        if (mainSkeleton && cr.height) {
          mainSkeleton.style.setProperty('height', `${cr.height + cr.top + cr.y}px`, 'important');
        }
      }
    });
    if (elem) {
      headerO.disconnect();
      headerO.unobserve(elem);
      headerO.observe(elem);
    }
    if (skeleton) {
      skeletonO.disconnect();
      skeletonO.unobserve(skeleton);
      skeletonO.observe(skeleton);
    }

    return () => {
      headerO.disconnect();
      if (elem) {
        headerO.unobserve(elem);
      }
      skeletonO.disconnect();
      if (skeleton) {
        skeletonO.unobserve(skeleton);
      }
    };
  }, []);
  const colors = configLQTheme('Y');
  const feature = document.getElementById('LQwidgetIntegration');
  const fiosBp = document.getElementById('skipped-main-content');
  return (
    <SkeletonWrapperMinLQ positionEmpty={feature || fiosBp} className="skeleton_mini_lq" bgColor={colors?.bgColor || ''}>
      <div className="child_wrapper">
        <div className="first_wrapper">
          <SkeletonBox height={15} style={{ width: '80%', marginBottom: 8 }} />
          <SkeletonBox width={200} height={15} style={{ width: '98%', marginBottom: 0 }} />
        </div>
        <SkeletonBox className="hr_input_box" height={2} style={{ width: '100%' }} />
        <div className="second_wrapper">
          <div className="input_container">
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '42%', marginBottom: 5, display: 'flex', alignItems: 'center' }}>
                <SkeletonBox height={15} style={{ width: '80%', marginRight: 5 }} />
                <SkeletonBox height={15} style={{ width: '10px', height: '10px', borderRadius: '50%' }} />
              </div>
              <div style={{ width: '30%', marginBottom: 5, display: 'flex', alignItems: 'center' }}>
                <SkeletonBox height={15} style={{ width: '80%', marginRight: 5, marginLeft: 'auto' }} />
                <SkeletonBox height={15} style={{ width: '10px', height: '10px', borderRadius: '50%' }} />
              </div>
            </div>
            <SkeletonBox className="input_box" width={500} height={35} style={{ width: '100%' }} />
          </div>
          <SkeletonBox className="button_skeleton" width={250} height={35} style={{ width: 180, borderRadius: 20 }} />
        </div>
      </div>
    </SkeletonWrapperMinLQ>
  );
};

export default MinLQSkeleton;

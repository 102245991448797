import React from 'react';
import PropTypes from 'prop-types';

class AddressEditableMinilq extends React.Component {
  render() {
    const { streetAddress, addressUnit } = this.props;
    return (
      <div id="address-edit-wrapper">
        <div id="ae-street-wrapper" data-cs-mask>
          {streetAddress}
          {addressUnit}
        </div>
      </div>
    );
  }
}

export default AddressEditableMinilq;
AddressEditableMinilq.propTypes = {
  streetAddress: PropTypes.string,
  addressUnit: PropTypes.string,
};

import { UnitLookUp as Action } from '../../constants/actionTypes';

const initialState = {
  customerUnitDetails: null,
  isUnitRequired: false,
  units: null,
  unitInput: '',
  unitSelected: false,
  unitsAutocompleteList: [],
  unitsDropdownLimitExceded: false,
  fetchedUnitsStreetId: '',
  unitInputError: false,
  unitInputSuccess: true,
  updateUserApartment: false,
};

const unitLookupReducer = (state = initialState, action) => {
  switch (action.type) {
    case Action.UPDATE_UNIT_DETAILS:
      return { ...state, customerUnitDetails: action.value };
    case Action.RESET_UNIT_DETAILS:
      return { ...state, customerUnitDetails: null };
    case Action.UPDATE_UNIT_COLLECTION:
      return { ...state, units: action.value };
    case Action.UNIT_SELECTED:
      return { ...state, unitSelected: action.value };
    case Action.UPDATE_DROPDOWN_LIMIT_STATUS:
      return { ...state, unitsDropdownLimitExceded: action.value };
    case Action.UPDATE_UNIT_REQUIREMENT:
      return { ...state, isUnitRequired: action.value };
    case Action.UPDATE_UNIT_INPUT:
      return { ...state, unitInput: action.value };
    case Action.UPDATE_UNIT_AUTOCOMPLET_LIST:
      return { ...state, unitsAutocompleteList: action.value };
    case Action.UPDATE_FETCHED_UNITS_STREET_ID:
      return { ...state, fetchedUnitsStreetId: action.value };
    case Action.SET_UNIT_INPUT_SUCCESS:
      return { ...state, unitInputSuccess: action.value };
    case Action.SET_UNIT_INPUT_ERROR:
      return { ...state, unitInputError: action.value };
    case Action.UPDATE_USER_APARTMENT:
      return { ...state, updateUserApartment: action.value };
    case Action.RESTORE_DEFAULTS:
      return initialState;

    default:
      return state;
  }
};

export default unitLookupReducer;

import React from 'react';
// import HomeLQMain from '../home-lq';

const HomeLQMain = React.lazy(() => import('../home-lq'));

const HomeLQView = (props) => (
  <React.Suspense fallback={<div />}>
    <HomeLQMain {...props} />
  </React.Suspense>
);
export default HomeLQView;

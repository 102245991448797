import { updateHomeLQDimensions } from '../components/loop-qual-app/actions';

export function isNotNull(value) {
  if (typeof value !== 'string' && value !== undefined && value !== null) {
    return true;
  }
  if (typeof value === 'string' && value !== undefined) {
    return true;
  }
  return false;
}

export function setModalScroll(offset = 0) {
  const scrollEl = document.querySelector('#lqw-modal-container #scrollbar-view') || document.querySelector('#lqw-modal-container');
  if (scrollEl) {
    scrollEl.scrollTop = offset;
  }
}

export function covertTime(time) {
  try {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? '0' : ''}${minutes} : ${seconds < 10 ? '0' : ''}${seconds}`;
  } catch (e) {
    return '';
  }
}

export const getHomeLQImageDimensions = (images, dispatch) => {
  // console.log('items', images);
  // return;
  const totalImages = Object?.keys(images);
  totalImages?.forEach((item) => {
    const prelq = {};
    const dimension = { width: '100%', height: '480px' };
    const img = new Image();
    img.src = images[item];
    img.onload = () => {
      dimension.width = img.naturalWidth;
      dimension.height = img.naturalHeight;
      prelq[item] = dimension;
      if (dispatch) {
        dispatch(updateHomeLQDimensions(prelq));
      }
      // console.log('dimensions', img.naturalWidth, img.naturalHeight);
    };
  });
};

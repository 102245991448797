import { Modal } from '../../../constants/actionTypes';
import { resetLboModal } from '../../loop-qual-app/actions';

export const openModalWindow = (modalId, dispatch) => {
  setTimeout(() => {
    dispatch({ type: Modal.OPEN_MODAL, value: modalId });
  });
};
export const closeModalWindow = (dispatch) => {
  dispatch(resetLboModal());
  if (window.lpWirelineModalCloser) {
    window.lpWirelineModalCloser('LQAClose');
  }
  setTimeout(() => {
    dispatch({ type: Modal.CLOSE_MODAL, value: '' });
  });
  if (window.vzlqw) {
    window.vzlqw.isvzlqwModalOpened = false;
    if (window.chatWindowInBackGround) {
      window.chatWindowInBackGround(false);
    }
  }
};
export const setModalWindowTitle = (title, dispatch) => {
  dispatch({ type: Modal.SET_TITLE, value: title });
};

export const existingCustomerModal = (dispatch, input) => {
  dispatch({ type: Modal.EXISTINGCUSTOMER_MODAL, value: input });
};

import { Loader as Action } from '../../../constants/actionTypes';

const initialState = {
  loaderStatus: false,
};

function loaderReducer(state = initialState, actions) {
  switch (actions.type) {
    case Action.SHOW_LOADER:
      return { ...state, loaderStatus: true };
    case Action.HIDE_LOADER:
      return { ...state, loaderStatus: false };
    default:
      return state;
  }
}
export default loaderReducer;

import { FloorLookUp } from '../../constants/actionTypes';

const initialState = {
  floorNumber: '',
  validFloorNumber: false,
};

function floorDetailsReducer(state = initialState, actions) {
  switch (actions.type) {
    case FloorLookUp.UPDATE_FLOOR_NUMBER:
      return { ...state, floorNumber: actions.value && actions.value.floorNo, validFloorNumber: !!actions.value.status };

    default:
      return state;
  }
}

export default floorDetailsReducer;

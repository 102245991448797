import callingApi from '../../services/apiService';
import { apiUrl } from '../../constants/api-urls';
import { MovesQualification as MVActionTypes } from '../../constants/actionTypes';
import { logMetrics } from '../../services/logService';
import { showLoader, hideLoader } from '../common/loader/actions';
import { LQ_VIEWS, MethodType } from '../../constants/common';
import { updateCurrentView } from '../loop-qual-app/actions';
import { getCookie } from '../../utils/lqServiceUtil';

export const checkLoggedInMV = (dispatch) => {
  try {
    const url = apiUrl().fetchAuthDetailsMV;
    dispatch(showLoader());
    const location =
      window.location.search &&
      (window.location.search.indexOf('testQualification') > -1 ||
        window.location.search.indexOf('nsa=y') > -1 ||
        window.location.search.indexOf('NSA=Y') > -1)
        ? 'Y'
        : null;
    let flowName;
    if (location && location === 'Y') {
      flowName = 'test|';
    }
    const requiredflowType = { flowType: flowName };

    return new Promise((resolve, reject) => {
      callingApi(url, MethodType.POST, {}, 'application/json', requiredflowType)
        .then((response) => {
          dispatch({ type: MVActionTypes.PROFILE_QUALIFICATION_DETAILS, value: response.data });
          if (response.data && response.data.accountLevelInfo && response.data.accountLevelInfo[0]) {
            logMetrics('checkLoggedInMVSuccess', response, 'Info');
            if (
              response.data.accountLevelInfo[0].pendingMoveOrderDetails &&
              response.data.accountLevelInfo[0].pendingMoveOrderDetails.isPendingMoveOrder
            ) {
              dispatch(updateCurrentView(LQ_VIEWS.MULTIPLE_SERVICES_QUALIFIED_MV_VIEW));
              dispatch({ type: MVActionTypes.IS_PENDING_MOVE_SERVICE, value: true });
            } else if (response.data.accountLevelInfo[0].paymentInfo && response.data.accountLevelInfo[0].paymentInfo.isBalancePastDue) {
              dispatch(updateCurrentView(LQ_VIEWS.MULTIPLE_SERVICES_QUALIFIED_MV_VIEW));
              dispatch({ type: MVActionTypes.IS_BALANCE_DUE, value: true });
            } else if (response.data.accountLevelInfo[0].fiosServiceDetails && response.data.accountLevelInfo[0].fiosServiceDetails.hasFiosService) {
              if (response.data.accountLevelInfo[0].lineLevelInfo.length === 0) {
                dispatch(updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION));
                dispatch(setPreferredService('Fios'));
                const userinfo = getCookie('userinfo');
                if (!userinfo) {
                  window.location.href =
                    'https://www.verizon.com/consumer/myverizon/router?target=https%3A%2F%2Fwww.verizon.com%2Fhome%2Finternet%3Ftype%3Dmoving%26auth%3DY';
                }
              } else if (response.data.accountLevelInfo[0].lineLevelInfo && response.data.accountLevelInfo[0].lineLevelInfo.length > 0) {
                dispatch(updateCurrentView(LQ_VIEWS.MULTIPLE_SERVICES_QUALIFIED_MV_VIEW));
              }
            } else if (response.data.accountLevelInfo[0].lineLevelInfo && response.data.accountLevelInfo[0].lineLevelInfo.length > 1) {
              dispatch(updateCurrentView(LQ_VIEWS.MULTIPLE_SERVICES_QUALIFIED_MV_VIEW));
            } else if (
              response.data.accountLevelInfo[0].lineLevelInfo.length > 0 &&
              response.data.accountLevelInfo[0].lineLevelInfo[0].mtnStatus &&
              response.data.accountLevelInfo[0].lineLevelInfo[0].mtnStatus.mtnStatusReasonCode === 'DB'
            ) {
              dispatch(updateCurrentView(LQ_VIEWS.MULTIPLE_SERVICES_QUALIFIED_MV_VIEW));
              dispatch({ type: MVActionTypes.IS_DELAYED_BILLING, value: true });
            } else if (
              response.data.accountLevelInfo[0].lineLevelInfo.length === 1 &&
              response.data.accountLevelInfo[0].lineLevelInfo[0].mtnStatus &&
              response.data.accountLevelInfo[0].lineLevelInfo[0].mtnStatus.isMtnSuspended
            ) {
              dispatch(updateCurrentView(LQ_VIEWS.MULTIPLE_SERVICES_QUALIFIED_MV_VIEW));
              dispatch({ type: MVActionTypes.IS_MTN_SUSPENDED, value: true });
            } else if (
              response.data.accountLevelInfo[0].lineLevelInfo.length === 1 &&
              response.data.accountLevelInfo[0].lineLevelInfo[0].serviceOrder &&
              response.data.accountLevelInfo[0].lineLevelInfo[0].serviceOrder.lineItemStatusDescription === "LINE READY TO GOTO SWITCH" && 
              response.data.accountLevelInfo[0].lineLevelInfo[0].serviceOrder.pending === "true"
            ) {
              dispatch(updateCurrentView(LQ_VIEWS.MULTIPLE_SERVICES_QUALIFIED_MV_VIEW));
              dispatch({ type: MVActionTypes.IS_PENDING_SERVICE_ORDER, value: true });
            } else {
              dispatch(updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION));
              if (response.data.accountLevelInfo[0].lineLevelInfo.length > 0) {
                dispatch(setPreferredService(response.data.accountLevelInfo[0].lineLevelInfo[0]));
                window.sessionStorage.setItem('networkBandwidthType', response.data.accountLevelInfo[0].lineLevelInfo[0].networkBandwidthType);
              }
            }
          } else {
            logMetrics('checkLoggedInMVFail', response, 'Error');
          }
          dispatch(hideLoader());
          resolve(response);
        })
        .catch((error) => {
          dispatch(hideLoader());
          logMetrics('checkLoggedInMVFailure', error.message, 'Error');
          reject(error);
        });
    });
  } catch (error) {
    dispatch(hideLoader());
    logMetrics('checkLoggedInMVFailure', error.message, 'Error');
  }
};

export const setPreferredService = (lineLevelInfo) => ({ type: MVActionTypes.SET_PREFERRED_MV_SERVICE, value: lineLevelInfo });

export const setDirectMove = (directMove) => ({ type: MVActionTypes.DIRECT_MOVE, value: directMove });

export const setdisConnectMove = (disConnectMove) => ({ type: MVActionTypes.DIRECT_MOVE, value: disConnectMove });

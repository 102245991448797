import React from 'react';
import styled from 'styled-components';

const SkeletonWrapper = styled.div`
  display: block;
  width: 100%;
  height: ${(props) => (props.height ? `${props.height}px` : '1em')};
  @media screen and (max-width: 767px) {
    ${(props) => (props.mobileHeight ? `height: ${props.mobileHeight}px;` : ``)}
  }
  position: relative;
  overflow: hidden;
  background-color: ${(props) => props.skeletonColor || `#dddbdd`};
  ${(props) => (props.maxWidthFull || props.maxWidth ? `max-width: ${props.maxWidth || '100%'};` : ``)}
  border-radius: 5px;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 3s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
`;
const SkeletonBox = (props) => <SkeletonWrapper className="skeletonWrapper" {...props} />;
export default SkeletonBox;

import { BulkQualification as BKActionTypes } from '../../constants/actionTypes';

const initialState = {
  bulkProfileDetails: null,
  preferredbulkService: null,
  preferredbulkServiceDetail: null,
  singleFromBulk: false,
  qualifiedlist: '',
  isFwaExisting: false,
  selectedFromList: '',
};

const bulkReducer = (state = initialState, action) => {
  switch (action.type) {
    case BKActionTypes.PROFILE_BULK_QUALIFICATION_DETAILS:
      return { ...state, bulkProfileDetails: action.value };
    case BKActionTypes.SET_PREFERRED_BULK_SERVICE:
      return { ...state, preferredbulkService: action.value };
    case BKActionTypes.SET_PREFERRED_BULK_SERVICE_DETAIL:
      return { ...state, preferredbulkServiceDetail: action.value };
    case BKActionTypes.SET_QUALIFIED:
      return { ...state, qualifiedlist: action.value };
    case BKActionTypes.SELECTED_LISTED:
      return { ...state, selectedFromList: action.value };
    case BKActionTypes.FWA_EXISTING:
      return { ...state, isFwaExisting: action.value };
    case BKActionTypes.SET_SINGLE_BULKQUAL:
      return { ...state, singleFromBulk: action.value };
    case BKActionTypes.RESET_BULK_DETAILS:
      return initialState;
    default:
      return state;
  }
};

export default bulkReducer;

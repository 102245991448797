import { AddressLookUp as Action } from '../../constants/actionTypes';
import { getStreetAddress } from '../../services/lqService';
import { catchJSException, logMetrics } from '../../services/logService';
import { CommonLQ } from '../../constants/common';
import { updateUserInput, onUnitSelection } from '../units-lookup/actions';

export const resetStreetSelection = () => ({ type: Action.RESET_STREET_SELECTION });

export const onStreetSearch = (userAddressInput, dispatch, autogeo) => {
  try {
    return new Promise((resolve, reject) => {
      let showLQBtn = !!(userAddressInput.length > 7);
      if (showLQBtn && userAddressInput.charAt(0) !== ' ' && userAddressInput.charAt(0) !== ',') {
        showLQBtn = true;
      } else {
        showLQBtn = false;
      }
      showLQCta(dispatch, showLQBtn);
      getStreetAddress(userAddressInput, dispatch)
        .then((response) => {
          logMetrics('onStreetSearch', 'ONSTREETSEARCHSUCCESS', 'Info');
          let formattedResponse = response.slice(0, 10);
          const fullResponse = response;
          if (response && response.length) {
            formattedResponse = formattedResponse.map((a) => {
              if (a && a.disprawstr) {
                a.disprawstr = a.disprawstr
                  .toLowerCase()
                  .replace(/\b\w/g, (l) => l.toUpperCase())
                  .replace(
                    /\s\b(([Aa][EeLlKkSsZzRr])|([Cc][AaOoTt])|([Dd][EeCc])|([Ff][MmLl])|([Gg][AaUu])|([Hh][Ii])|([Ii][DdLlNnAa])|([Kk][SsYy])|([Ll][Aa])|([Mm][EeHhDdAaIiNnSsOoTt])|([Nn][EeVvHhJjMmYyCcDd])|([Mm][Pp])|([Oo][HhKkRr])|([Pp][WwAaRr])|([Rr][Ii])|([Ss][CcDd])|([Tt][NnXx])|([Uu][Tt])|([Vv][TtIiAa])|([Ww][AaVvIiYy]))\,/,
                    (l) => l.toUpperCase(),
                  )
                  .replace(/\sUsa/, (l) => l.toUpperCase());
              }
              return a;
            });
          }
          resolve(formattedResponse);
          const showMore = {
            disprawstr: 'Show more results',
            dispstr: 'Show more results',
          };
          const showLess = {
            disprawstr: 'Show less results',
            dispstr: 'Show less results',
          };
          if (response && response.length > 10) {
            fullResponse.push(showLess);
            formattedResponse.push(showMore);
          }
          fullSuggestion(dispatch, fullResponse);
          halfSuggestion(dispatch, formattedResponse);

          addressCollectionUpdate(dispatch, formattedResponse);
          setTimeout(() => {
            if (autogeo) {
              const streetInput = document.querySelector('#streetaddress');
              if (streetInput) {
                streetInput.focus();
              }
              document.querySelector('a.item-highlighted').click();
            }
          }, 200);
        })
        .catch((error) => {
          reject(error);
          if (!(error.message === 'old Request Cancelled')) {
            logMetrics('onStreetSearch', error.message, 'Error');
          }
          dispatch(resetAddressCollection());
        });
    });
  } catch (error) {
    catchJSException('LOOPQUAL2.0', 'onStreetSearch', error);
    logMetrics('onStreetSearch', error.message, 'Error');
  }
};
export const showFullResponse = (dispatch, response) => {
  addressCollectionUpdate(dispatch, response);
};

export const onStreetSelection = (streetDetails) => ({ type: Action.UPDATE_STREET_DETAILS, value: streetDetails });

export const setRevisitAddress = (address) => ({ type: Action.REVISIT_ADDRESS, value: address });
export const setRevisitScenario = (revisit) => ({ type: Action.REVISIT_SCENARIO, value: revisit });

export const updateStreetInput = (input) => ({ type: Action.UPDATE_STREET_INPUT, value: input });
export const streetEmptyError = (input) => ({ type: Action.STREET_EMPTY_ERROR, value: input });
export const setStreetError = (input) => ({ type: Action.SET_STREET_ERROR, value: input });
export const setStreetGeoError = (input) => ({ type: Action.SET_STREET_GEO_ERROR, value: input });
export const sameAddressError = (input) => ({ type: Action.SAME_ADDRESS_ERROR, value: input });
export const invalidAddressError = (input) => ({ type: Action.INVALID_ADDRESS_ERROR, value: input });
export const resetAddressCollection = () => ({ type: Action.UPDATE_ADDRESS_COLLECTION, value: [] });

export const restoreDefaults = () => ({ type: Action.RESTORE_DEFAULTS });

export const showLQCta = (dispatch, input) => {
  dispatch({ type: Action.SHOW_LQCTA, value: input });
};
export const updateSuggestedAddr = (input, dispatch) => {
  dispatch({ type: Action.SET_PARSED_ADDRESS, value: input });
};
const addressCollectionUpdate = (dispatch, response) => {
  resetAddressCollectionUpdate(dispatch).then(() => {
    dispatch({ type: Action.UPDATE_ADDRESS_COLLECTION, value: response });
  });
};
const resetAddressCollectionUpdate = (dispatch) =>
  new Promise((resolve) => {
    dispatch({ type: Action.UPDATE_ADDRESS_COLLECTION_RESET, value: [] });
    resolve();
  });

export const fullSuggestion = (dispatch, response) => {
  resetAddressCollectionUpdate(dispatch).then(() => {
    dispatch({ type: Action.FULL_SUGGESTION, value: response });
  });
};
export const halfSuggestion = (dispatch, response) => {
  resetAddressCollectionUpdate(dispatch).then(() => {
    dispatch({ type: Action.HALF_SUGGESTION, value: response });
  });
};
export const getSHA256Val = async (dispatch, item, source) => {
  let hashHex = '';
  const locusID = source === 'street' ? item.locusID + CommonLQ.shaKey : item.ntasAddrID + CommonLQ.shaKey;
  try {
    const msgUint8 = new TextEncoder().encode(locusID);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    if (source === 'street') {
      item.hashedLocusId = hashHex;
      dispatch(onStreetSelection(item));
    } else if (source === 'ntas') {
      item.hashedNtasAddrID = hashHex;
      dispatch(onStreetSelection(item));
    }
  } catch (error) {
    console.error('getSHA-256 failed', error);
  }
};

export const setAddressDetail = (dispatch, res) => {
  const { addressLine1, addressLine2, city: muni, state, zipCode: zip, hashedLocusId, subLocationId: locusID, ntasAddressId } = res;

  const addrInfo = {
    addressLine1,
    addressLine2,
    muni,
    state,
    zip,
    hashedLocusId,
    locusID,
    dispstr: addressLine1,
    disprawstr: `${`${addressLine1}, ${muni}, ${state}, ${zip}`}`,
    ntasAddrID: ntasAddressId || '',
  };
  dispatch(onStreetSelection(addrInfo));
  if (addressLine2) {
    dispatch(updateUserInput(addressLine2));
  }
  return addrInfo;
};

export const setAddressDetailForExternal = (dispatch, res) => {
  const {
    addressLine1,
    addressLine2,
    city,
    stateProvince,
    postalCodeBase,
    hashedLocusId,
    baseLocationId,
    subLocation,
    externalReferences,
    locationId,
    locationType,
    ntasAddrId,
  } = res;
  let ntasAddrIDVal = '';
  const params = new URLSearchParams(window.location.search);
  const basentasid = params.get('basentasid') ? params.get('basentasid') : null;
  if (ntasAddrId && ntasAddrId !== 0) {
    ntasAddrIDVal = ntasAddrId;
  } else if (basentasid) {
    ntasAddrIDVal = basentasid;
  } else if (externalReferences && externalReferences.length > 0) {
    for (let i = 0; i <= externalReferences.length - 1; i++) {
      if (externalReferences[i].externalSource === 'IVAPP_FIOS') {
        ntasAddrIDVal = externalReferences[i].externalId;
      } else if (externalReferences[i].externalSource === 'ntasif' || externalReferences[i].externalSource === 'NTASIF') {
        ntasAddrIDVal = externalReferences[i].externalId;
      }
    }
  }

  const addrInfo = {
    addressLine1,
    addressLine2: subLocation,
    muni: city,
    state: stateProvince,
    zip: postalCodeBase,
    hashedLocusId,
    locusID: baseLocationId,
    dispstr: addressLine1,
    ntasAddrID: ntasAddrIDVal,
  };

  if (locationType === 'MDU') {
    const unitInfo = {
      location_id: locationId,
      ntasAddrId: ntasAddrIDVal,
      sublocation: subLocation,
    };
    dispatch(onUnitSelection(unitInfo));
  }

  dispatch(onStreetSelection(addrInfo));
  if (addressLine2) {
    dispatch(updateUserInput(subLocation));
  }
  return addrInfo;
};

export const setAddressDetailForGeo = (dispatch, res) => {
  const {
    addressLine1,
    addressLine2,
    city,
    stateProvince,
    postalCodeBase,
    hashedLocusId,
    baseLocationId,
    subLocation,
    externalReferences,
    locationId,
    locationType,
    ntasAddrId,
  } = res;
  let ntasAddrIDVal = '';
  if (ntasAddrId && ntasAddrId !== 0) {
    ntasAddrIDVal = ntasAddrId;
  } else if (externalReferences && externalReferences.length > 0) {
    for (let i = 0; i <= externalReferences.length - 1; i++) {
      if (externalReferences[i].externalSource === 'IVAPP_FIOS') {
        ntasAddrIDVal = externalReferences[i].externalId;
      } else if (externalReferences[i].externalSource === 'ntasif' || externalReferences[i].externalSource === 'NTASIF') {
        ntasAddrIDVal = externalReferences[i].externalId;
      }
    }
  }

  const addrInfo = {
    addressLine1,
    addressLine2: subLocation,
    muni: city,
    state: stateProvince,
    zip: postalCodeBase,
    hashedLocusId,
    locusID: baseLocationId,
    dispstr: addressLine1,
    ntasAddrID: ntasAddrIDVal,
  };

  if (locationType === 'MDU') {
    const unitInfo = {
      location_id: locationId,
      ntasAddrId: ntasAddrIDVal,
      sublocation: subLocation,
    };
    dispatch(onUnitSelection(unitInfo));
  }

  dispatch(onStreetSelection(addrInfo));
  if (addressLine2) {
    dispatch(updateUserInput(subLocation));
  }
  return addrInfo;
};
export const setRevisitQualified = (qualified) => ({type: Action.REVISIT_QUALIFIED, value: qualified});

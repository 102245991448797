import { Fios } from '../../constants/actionTypes';

const initialState = {
  saveResponse: null,
  fiosQualifiedView: true,
  inServiceView: false,
  inTWSServiceView: false,
  resumeCartView: false,
  resumeSendCartView: false,
  processSaveCartInvoked: false,
  discountMnh: false,
  sentSmsOrEmail: false,
  signinView: false,
  signinVerificationView: false,
  signInMHBasicPhoneVerificationView: false,
  newService: 'Y',
  mhMtn: '',
  mhZipCode: '',
  mhVerificationCode: '',
  mhEligible: false,
  validateSignInResponse: '',
  apiCount: 1,
  signInErrorMH: false,
  isAuthinticated: false,
  signMHSuccess: false,
  enableVerifyButton: false,
  igVerificationInitialed: false,
  vendorDetails: '',
};

const fiosReducer = (state = initialState, action) => {
  switch (action.type) {
    case Fios.SAVE_RESPONSE:
      return { ...state, saveResponse: action.value };
    case Fios.FIOSQUALIFIED_VIEW:
      return { ...state, fiosQualifiedView: action.value };
    case Fios.INSERVICE_VIEW:
      return { ...state, inServiceView: action.value };
    case Fios.RESUMECART_VIEW:
      return { ...state, resumeCartView: action.value };
    case Fios.RESUMESENDCART_VIEW:
      return { ...state, resumeSendCartView: action.value };
    case Fios.PROCESSSAVECART_INVOKED:
      return { ...state, processSaveCartInvoked: action.value };
    case Fios.SENT_SMSOREMAIL:
      return { ...state, sentSmsOrEmail: action.value };
    case Fios.NEW_SERVICE:
      return { ...state, newService: action.value };
    case Fios.SIGNIN_VIEW:
      return { ...state, signinView: action.value };
    case Fios.SIGNIN_VERIFICATION_VIEW:
      return { ...state, signinVerificationView: action.value };
    case Fios.SIGNIN_BASIC_PHONE_VERIFICATION_VIEW:
      return { ...state, signInMHBasicPhoneVerificationView: action.value };
    case Fios.DISCOUNT_MOBILE_HOME:
      return { ...state, discountMnh: action.value };
    case Fios.ENABLE_VERIFY_BUTTON:
      return { ...state, enableVerifyButton: action.value };
    case Fios.SIGNIN_SUCCESS:
      return { ...state, signMHSuccess: action.value };
    case Fios.MH_MTNUMBER:
      return { ...state, mhMtn: action.value };
    case Fios.MH_ZIPCODE:
      return { ...state, mhZipCode: action.value };
    case Fios.VALIDATE_RESPONSE:
      return { ...state, validateSignInResponse: action.value };
    case Fios.MH_VERIFICATIONCODE:
      return { ...state, mhVerificationCode: action.value };
    case Fios.MH_ELIGIBLE:
      return { ...state, mhEligible: action.value };
    case Fios.API_COUNT:
      return { ...state, apiCount: state.apiCount + 1 };
    case Fios.ERROR_SIGNIN_MH:
      return { ...state, signInErrorMH: action.value };
    case Fios.IS_LOGIN_AUTHENTICATED:
      return { ...state, isAuthinticated: action.value };
    case Fios.VENDOR_DETAILS:
      return { ...state, vendorDetails: action.value };
    case Fios.SET_REFERRAL_URL:
      return { ...state, setReferralUrl: action.value };
    case Fios.IG_VERIFICATION_START:
      return { ...state, igVerificationInitialed: action.value };
    case Fios.RESTOREFIOS_DEFAULTS:
      return initialState;
    default:
      return state;
  }
};

export default fiosReducer;

import React from 'react';
import ReactDOM from 'react-dom';
import VZLQWidget from './app';
import Config from './config';

let widgetComponent = null;
function app() {
  widgetComponent = React.createRef();
  ReactDOM.render(<VZLQWidget ref={widgetComponent} />, document.getElementById(Config.targetElementId));
}

app(window);

export default app;

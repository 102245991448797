import React from 'react';
import PropTypes from 'prop-types';
import { setModalWindowTitle } from './modal/action';
import { getSignInIframeUrl } from '../../utils/lqServiceUtil';
import { openViewVzTag } from '../../utils/unifiedVzTag';
import { Flowtype } from '../../constants/common';
import { getTwsStickyLQ } from '../../config';

class SignIn extends React.PureComponent {
  componentDidMount() {
    setModalWindowTitle('', this.props.dispatch);
    if (this.props.loopQualApp.flowType === Flowtype.MOVERS) {
      openViewVzTag('signin|move');
    } else if (getTwsStickyLQ() === 'Y') {
      openViewVzTag('signin|TWS');
    }
    const closeButton = document.querySelector('.closeButton');
    if (closeButton) {
      setTimeout(() => {
        closeButton.focus();
      }, 6000);
    }
  }

  render() {
    const redirectUrl = getSignInIframeUrl(this.props.lqResult, this.props.couponLQ, this.props.loopQualApp, this.props.sessionTimeout);

    return (
      <React.Fragment>
        <iframe className="signin" title={redirectUrl} src={redirectUrl} />
      </React.Fragment>
    );
  }
}
export default SignIn;
SignIn.propTypes = {
  dispatch: PropTypes.string,
  lqResult: PropTypes.string,
  couponLQ: PropTypes.string,
  loopQualApp: PropTypes.string,
  sessionTimeout: PropTypes.any,
};

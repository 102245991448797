import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button as ButtonComp } from '@vds/buttons';

class Button extends Component {
  render() {
    const { children } = this.props;
    return <ButtonComp {...this.props}>{children}</ButtonComp>;
  }
}

Button.defaultProps = {
  disabled: false,
  display: 'inline-block',
  inverted: false,
  primary: false,
  secondary: false,
  size: 'large',
  width: 'auto',
};
Button.propTypes = {
  disabled: PropTypes.bool,
  display: PropTypes.oneOf(['flex', 'inline-block', 'block']),
  id: PropTypes.string,
  inverted: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  size: PropTypes.oneOf(['normal', 'tiny', 'small', 'large', 'block']),
  width: PropTypes.oneOf(['number', 'string', 'auto']),
  children: PropTypes.any,
  surface: PropTypes.any,
};
export default Button;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledLoader = styled.div`
  background-color: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  position: ${(props) => (props.$absoluteLoader ? 'absolute' : 'fixed')};
  display: block;
  -webkit-animation: eMLfYp 0.15s linear;
  animation: eMLfYp 0.15s linear;
  opacity: 0.8;

  .loader {
    outline: none;
    display: block;
    -webkit-animation: eMLfYp 0.15s linear;
    animation: eMLfYp 0.15s linear;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: '',
    right: '',
    transform: translate(-50%, -50%);
    margin: auto;
    width: 2.5rem;
    height: 2.5rem;
    display: block;
    z-index: 3;
    border: 0.25rem solid #000000;
    border-top-color: transparent;
    border-radius: 50%;
    -webkit-animation: cVuWGa 0.5s infinite linear;
    animation: cVuWGa 0.5s infinite linear;
  }

  @-webkit-keyframes eMLfYp {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes eMLfYp {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes cVuWGa {
    from {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
    }

    to {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes cVuWGa {
    from {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
    }

    to {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
class Loader extends React.PureComponent {
  render() {
    if (this.props.active || this.props.absoluteLoader) {
      return (
        <StyledLoader $absoluteLoader={this.props.absoluteLoader} aria-label="loader overlay">
          <div aria-label="loader animation" className="loader" />
        </StyledLoader>
      );
    }
    return <div />;
  }
}
Loader.defaultProps = {
  active: false,
  absoluteLoader: false,
};
Loader.propTypes = {
  active: PropTypes.bool,
  absoluteLoader: PropTypes.bool,
};
export default Loader;

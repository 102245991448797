import React from 'react';
import PropTypes from 'prop-types';
import { TextLink } from '@vds/buttons';
import { Body } from '@vds/typography';

class Link extends React.PureComponent {
  render() {
    const { linkText } = this.props;
    return (
      <Body>
        <TextLink {...this.props}>{linkText}</TextLink>
      </Body>
    );
  }
}
export default Link;

Link.propTypes = {
  linkText: PropTypes.string,
  id: PropTypes.string,
  surface: PropTypes.string,
  role: PropTypes.string,
};

export const customCreateAction = (type) => {
  const callback = (payload) => ({ type, payload });
  callback.type = type;
  if (type) return callback;
};
export const updateSessionView = customCreateAction('UPDATE_SESSION_VIEW');
export const setTimerValue = customCreateAction('SET_TIMER_VALUE');
export const resetSessionView = customCreateAction('RESET_SESSION_VIEW');
export const prospectExpiredView = customCreateAction('PROSPECT_EXPIRED_SESSION_VIEW');
export const loggedInExtendedView = customCreateAction('LOGGEDIN_EXTENDED_SESSION_VIEW');
export const loggedInExpiredView = customCreateAction('LOGGEDIN_EXPIRED_SESSION_VIEW');
export const updateElapseView = customCreateAction('UPDATE_ELAPSE_TIMER');
export const resetElapseTimer = customCreateAction('RESET_ELAPSE_TIMER');

/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import axios from 'axios';

const oldRequestCancelled = 'old Request Cancelled';
axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    config.timing = { startTime: Date.now() };
    return config;
  },
  (error) =>
    // Do something with request error
    Promise.reject(error),
);

// Add a response interceptor
axios.interceptors.response.use(
  (res) => {
    // Do something with response data
    const endTime = Date.now();
    res.config.timing.duration = endTime - res.config.timing.startTime;
    // console.log('interceptor response', response.config.timing.endTime - response.config.timing.startTime);
    return res;
  },
  (error) => {
    // Do something with response error
    const endTime = Date.now();
    error.message !== oldRequestCancelled && (error.config.timing.duration = endTime - error.config.timing.startTime);
    return Promise.reject(error);
  },
);

const api = (requestObj) =>
  new Promise((resolve, reject) => {
    axios(requestObj)
      .then((response) => {
        if (response.isAxiosError) {
          return reject(response.response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export default api;

import { Modal } from '../../../constants/actionTypes';

const initialState = {
  isModalOpened: false,
  modalId: '',
  modalTitle: '',
  existingCustomerModal: false,
};
function modalOverlay(state = initialState, action) {
  switch (action.type) {
    case Modal.OPEN_MODAL:
      return {
        ...state,
        isModalOpened: true,
        modalId: action.value,
      };
    case Modal.CLOSE_MODAL:
      return {
        ...state,
        isModalOpened: false,
        modalId: '',
        modalTitle: '',
      };
    case Modal.SET_TITLE:
      return { ...state, modalTitle: action.value };
    case Modal.EXISTINGCUSTOMER_MODAL:
      return { ...state, existingCustomerModal: action.value };
    default:
      return state;
  }
}
export default modalOverlay;

import React from 'react';
import PropTypes from 'prop-types';
import { logMetrics } from './services/logService';

const PageRefreshed = 'pagerefreshed';
const pageHasAlreadyBeenForceRefreshed = JSON.parse(window.localStorage.getItem(PageRefreshed) || 'false');
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    if (this.state.hasError) {
      logMetrics('ErrorBoundary-LQ', error, 'Error');
    }
    if (!pageHasAlreadyBeenForceRefreshed) {
      window.localStorage.setItem(PageRefreshed, 'true');
      return window.location.reload();
    }
  }

  render() {
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};

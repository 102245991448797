import callingApi from './apiService';
import { apiUrl } from '../constants/api-urls';
import { PAGEACTIVITY } from '../constants/actionTypes';
import { isNotNull } from '../utils/helperUtil';
import { genarateToken, checkTokenValidity, getCookie } from '../utils/lqServiceUtil';
import { MethodType } from '../constants/common';

const logUrl = apiUrl().kafkaPageLogs;

const IH_APP_LOGS = 'applogs';
const IH_PAGE_LOGS = 'pagelogs';

let visit_id = sessionStorage.getItem('visit_id');

export const logKafka = (logType, logSubType, logMessage, kafkalogUrl, logName, visitid, blockType) => {
  if (!visitid) {
    visitid = getVisitid();
  }

  let kafkaErrorMessage = '';
  if (logType === 'ERROR' && blockType !== 'try') {
    logToKibanna(logMessage, '');
  }

  if (logName === IH_APP_LOGS) {
    kafkaErrorMessage = {
      logtype: logType,
      logsubtype: logSubType,
      lastupdated: new Date().toUTCString(),
      logmessage: logMessage,
    };
  } else if (logName === IH_PAGE_LOGS) {
    kafkaErrorMessage = logMessage;
  }

  const logs = getLogMessage(logName, kafkaErrorMessage, visitid);
  const requiredAuth = { authToken: true };
  if (visitid && window.localStorage.getItem('visitor_id')) {
    if (!checkTokenValidity()) {
      genarateToken()
        .then((response) => {
          if (response.status === 200) {
            callingApi(logUrl, MethodType.POST, logs, 'text/plain', requiredAuth);
          }
        })
        .catch((error) => {
          logKafka('ERROR', 'cancelSmartcart', error, '', 'applogs');
        });
    } else {
      callingApi(logUrl, MethodType.POST, logs, 'text/plain', requiredAuth);
    }
  }
};
export const logMetrics = (methodName, userinfo, clicktype) => {
  const infodata = userinfo;
  const eventType = 'INFO';
  const pageName = window.location.pathname.split('/').pop().split('.')[0];
  const data = {
    messageType: clicktype,
    eventType,
    subEventType: '',
    vendorSubEventType: '',
    pageUrl: window.location.href,
    serviceUrl: null,
    method: methodName,
    errorMessage: '',
    subHeaderMessage: null,
    errorPage: null,
    // cookie: networkErrorJSON.e2erequestid || '', // digital_ig_session???
    serviceE2EId: null,
    note: '',
    loggerMessage: {
      message: infodata,
      loglevel: 'loglevel',
      id: null,
      name: 'LQWIDGET',
      errorCategory: '',
      userAgent: navigator.userAgent,
      pageTitle: pageName, // document.title,
      browserName: '',
      host: window.location.host,
      stackTrace: '',
      componentStack: methodName,
      isServiceErrorMessage: '',
    },
    isNseProspectFlow: isNseProspectFlow(),
    fraudResponse: null, // Used only for generic error page
    isGoToUrl: null, // curious as to how many are being logged and if these can be ommited.
    extraParams: null, // to avoid additional changes in order to log data that is not common across networm errors.
    newSOELoggingChangesEnabled: '',
    // sessionStorage: window?.vzFlags?.sessionStorageLoggingEnabled && eventType !== 'vendorScriptError' ? getSessionStorageInfo() : undefined,
  };
  const jsLogUrl = apiUrl().jsNotifyAsDynamic;
  const requiredAuth = { authToken: true };
  if (jsLogUrl) {
    callingApi(jsLogUrl, MethodType.POST, data, 'application/json', requiredAuth);
  }
};
export const isNseProspectFlow = () => {
  const isLoggedIn = !!getCookie('loggedIn') || !!window.sessionStorage.getItem('loggedIn');
  return !isLoggedIn;
};
export const logToKibanna = (data, level) => {
  const requiredAuth = { authToken: true };
  const logmsg = data ? `${data} JS_NOTIFY_LQ` : '';
  try {
    const KibanaLogMessage = {
      level: level === 'log' ? 'INFO' : 'ERROR',
      message: logmsg || '',
    };
    const url = apiUrl().jsNotify;
    // const url = 'https://api-dev2.ebiz.verizon.com/atomapi/v1/common/logger/logmsg';
    return new Promise((resolve) => {
      callingApi(url, MethodType.POST, KibanaLogMessage, '', requiredAuth)
        .then((response) => {
          const logResponse = response.data ? response.data : {};
          resolve(logResponse);
        })
        .catch((error) => {
          resolve(error);
        });
    });
  } catch (ex) {
    console.log(ex);
  }
};

function getLogMessage(topic, objectList, visitid) {
  let log = `${topic}||visitid=${visitid}`;
  for (const key in objectList) {
    log = `${log}||${key}=${objectList[key]}`;
  }
  if (topic === IH_APP_LOGS) {
    log += '||logsource=UI';
  }
  return log;
}

export const logPageDetailsInfo = (pageName, pageSubname, logMessage, actionType = null) => {
  const action = actionType === PAGEACTIVITY.UA ? PAGEACTIVITY.UA : 'pageAction';
  const kafkaPageMessage = {
    pagename: pageName,
    pagesubname: `${pageSubname}_${new Date().getTime()}`,
    action,
    time: new Date().toUTCString(),
    lastupdated: getTimeInEST(),
    pagejson: logMessage,
    duration: 0,
  };
  if (!visit_id) {
    visit_id = getVisitid();
  }

  logKafka('PAGE', pageName, kafkaPageMessage, logUrl, 'pagelogs', visit_id);
};

const getTimeInEST = () => {
  const date = new Date();
  const fullEstTime = getEstTime(date);
  // console.log('fullEstTime', fullEstTime);
  let arrDtTime;
  let time;
  // let strDate;
  let arrTime;
  let arrDate;
  let year;
  let month;
  let day;
  let hour;
  let minute;
  let second = null;
  let nbrHr = 0;

  if (fullEstTime.indexOf(',') > -1) {
    arrDtTime = fullEstTime.split(',');
    time = arrDtTime[1].trim().split(' '); // ["2:44:43", "PM"]
    // strDate = arrDtTime[0].trim();
    nbrHr = time[1] === 'PM' ? 12 : 0;
    arrTime = time[0].split(':');
    arrDate = arrDtTime[0].trim().split('/');
    (year = arrDate[2]), (month = arrDate[0]), (day = arrDate[1]), (hour = arrTime[0]), (minute = arrTime[1]), (second = arrTime[2]);
  } else {
    console.log('fullEstTime else fullEstTime', fullEstTime);
    const ieTime = fullEstTime.trim().split(' ');
    time = ieTime[1].trim().split(' '); // ["2:44:43", "PM"]
    // strDate = ieTime[0].trim();
    nbrHr = ieTime[2].trim() === 'PM' ? 12 : 0;
    arrTime = ieTime[1].split(':');
    arrDate = ieTime[0].trim().split('/');
    (year = cleanString(arrDate[2])),
      (month = cleanString(arrDate[0])),
      (day = cleanString(arrDate[1])),
      (hour = cleanString(arrTime[0])),
      (minute = cleanString(arrTime[1])),
      (second = cleanString(arrTime[2]));
  }
  hour = padZeroInTheStart(JSON.stringify(parseInt(hour.trim()) + nbrHr));
  minute = padZeroInTheStart(minute);
  second = padZeroInTheStart(second);
  month = padZeroInTheStart(month);
  day = padZeroInTheStart(day);
  const estTimeFormat = [hour, minute, second].join(':');
  const estDateFormat = [year, month, day].join('-');
  const timeString = `${estDateFormat} ${estTimeFormat}.${date.getMilliseconds()}`;
  // console.log('fullEstTime timeString', timeString);
  return timeString;
};

const padZeroInTheStart = (time) => (time.length === 2 ? time : `0${time}`);
const cleanString = (str) => str.replace(/[^0-9/]/gi, '');

function getEstOffsetFromUTC(aDate) {
  const aDateFullYear = aDate.getFullYear();
  const jan = new Date(aDateFullYear, 0, 1);
  const jul = new Date(aDateFullYear, 6, 1);

  if (aDate.getTimezoneOffset() < Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())) {
    return 4;
  }
  return 5;
}

function padZero(val) {
  return val < 10 ? `0${val}` : val;
}

const getEstTime = (dateIn) => {
  try {
    return dateIn.toLocaleString('en-US', { timeZone: 'America/New_York' });
  } catch (ex) {
    if (!(dateIn instanceof Date)) {
      console.error(ex);
    } else {
      // For non-modern browsers without locale and timeZone support (<=IE11, <Safari10, etc...)
      try {
        const dNowUTC = new Date(
          dateIn.getUTCFullYear(),
          dateIn.getUTCMonth(),
          dateIn.getUTCDate(),
          dateIn.getUTCHours(),
          dateIn.getUTCMinutes(),
          dateIn.getUTCSeconds(),
        );
        const estOffset = getEstOffsetFromUTC(dateIn) * 3600 * 1000;
        const estDate = new Date(dNowUTC - estOffset);

        const estDay = `${estDate.getMonth() + 1}/${estDate.getDate()}/${estDate.getFullYear()}`;
        let estHours = estDate.getHours();
        const amPm = estHours > 12 ? 'PM' : 'AM';
        estHours = estHours === 0 ? 12 : estHours % 12;

        const estMinutes = padZero(estDate.getMinutes());
        const estSeconds = padZero(estDate.getSeconds());
        const estTime = `${estHours}:${estMinutes}:${estSeconds}`;

        return `${estDay}, ${estTime} ${amPm}`;
      } catch (e) {
        console.error(e);
      }
    }
  }
};

const getVisitid = () => sessionStorage.getItem('visit_id');

export const catchJSException = (pageName, pageSubname, error) => {
  if (!visit_id) {
    visit_id = getVisitid();
  }

  let errMsg = '';
  console.log(error);
  if (isNotNull(error)) {
    if (isNotNull(error.stack) && error.stack !== '') {
      errMsg = error.stack;
    } else if (isNotNull(error.message) && error.message !== '') {
      errMsg = `${error.message}: js error in ${pageName} in ${pageSubname}`;
    } else {
      errMsg = `js error in ${pageName} in ${pageSubname}`;
    }
  }

  const getErrorLogUrl = apiUrl().kafkaErrorAppLogs;
  logKafka('JSERROR', `${pageName}_${pageSubname}`, errMsg, getErrorLogUrl, IH_APP_LOGS, visit_id);
};

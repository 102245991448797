export const configLQTheme = (monarchEnabled) => {
  const colorThemes = {
    grey_85: {
      bgColor: '#d8dada',
      backgroundColor: '#000000',
      borderColor: '#000000',
      fontColor: '#FFFFFF',
      color: '#000000',
      surface: 'light',
      themeName: 'grey_85',
    },
    monarch: {
      bgColor: '#F3EDE0',
      backgroundColor: '#000000',
      borderColor: '#000000',
      fontColor: '#FFFFFF',
      color: '#000000',
      surface: 'light',
      themeName: 'monarch',
    },
  };
  const setTheme = monarchEnabled === 'Y' ? 'monarch' : 'grey_85';
  const colors = colorThemes[setTheme];
  return colors;
};
